import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import { upload_image_s3 } from "../../DAL/country/country";
import { add_banner } from "../../DAL/banners";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Add_Banner(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState(true);
  const [english_image, setEnglish_Image] = useState("");
  const [english_image_file, setEnglish_Image_File] = useState("");
  const [arabic_image, setArabic_Image] = useState("");
  const [arabic_image_file, setArabic_Image_File] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    
    const data = {
      title,
      description,
      type,
      status:active,
      english_image,
      arabic_image,
    };
    const response = await add_banner(data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Banner Added Successfully", { variant: "success" });
      props.history.push("/banners");
    }
    
  
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setEnglish_Image_File(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setEnglish_Image(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };

   const fileTicketChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setArabic_Image_File(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setArabic_Image(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };
  
  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }
  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Add Banner</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
            <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Title"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e)=> setTitle(e.target.value)}
          name="title"

        />
         </Grid>


         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="desc"
          value={description}
          onChange={(e)=> setDescription(e.target.value)}
          name="desc"

        />
        </Grid>


         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel htmlFor="outlined-type-native-simple">Type</InputLabel>
        <Select
          native
          value={type}
          onChange={(e)=> setType(e.target.value)}
          label="Buy Type"
          inputProps={{
            name: 'type',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value="mobile">Mobile</option>
          <option value="website">Website</option>
        </Select>
      </FormControl>
          </Grid>

      

         <Grid item xs={12}>
                {english_image_file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={english_image_file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload English Image
                  </Button>
                </label>
              </Grid> 

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {arabic_image_file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={arabic_image_file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-ticket-file"
                  type="file"
                  onChange={fileTicketChangedHandler}
                />
                <label htmlFor="contained-button-ticket-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Arabic Image
                  </Button>
                </label>
              </Grid> 

          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={(event)=> setActive(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid> 
             

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Banner
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
