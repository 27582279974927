import { invokeApi } from "../../bl_libs/invokeApi";

export const countries_list = async () => {
  let requestObj = {
    path: `api/country/all_country_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page,limit,search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_country = (data) => {
  let requestObj = {
    path: `api/country/add_country`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_country = (id,data) => {
  let requestObj = {
    path: `api/country/edit_country/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const country_detail = (id) => {
  let requestObj = {
    path: `api/country/country_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const upload_image_s3 = (data) => {
  let requestObj = {
    path: `api/app_api/upload_image_s3`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const upload_ticket_image_s3 = (data) => {
  let requestObj = {
    path: `api/app_api/upload_module_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};