import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import {
  InputLabel,
  Select,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import { upload_image_s3 } from "../../DAL/country/country";
import { add_supplier } from "../../DAL/supplier";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom: 20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Add_Supplier(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_Number] = useState([
    {
      type: "office",
      contact: "",
    }
  ]);
  const [license_expiry_date, setLicense_Expiry_Date] = useState();
  const [active, setActive] = useState(true);
  const [license_image, setLicense_Image] = useState("");
  const [license_file, setLicense_File] = useState("");
  const [emirates_id_front_image, setEmirates_Id_Front_Image] = useState("");
  const [emirates_id_back_image, setEmirates_Id_Back_Image] = useState("");
  const [emirates_id_front_file, setEmirates_Id_Front_File] = useState("");
  const [emirates_id_back_file, setEmirates_Id_Back_File] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);



  const handleback = () => {
    history.goBack();
  };

  const handleAddPhoneNumber = () => {
    setPhone_Number((section) => [
      ...phone_number,
      {
        type: "office",
        contact: ""
      },
    ]);
  };

  const handlePhoneNumberChange = (id, key, value) => {
    setPhone_Number((number) =>
      number.map((sec,index) => {
        if (index === id) {
          return {
            ...sec,
            [key]: value,
          };
        } else {
          return sec;
        }
      })
    );
  };

  const handleDeletePhoneNumber = (id) => {
    setPhone_Number((number) => number.filter((sec,index) => index !== id));
  };

  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);

    const data = {
      name,
      email,
      phone_number,
      trade_license: license_image,
      national_id_front: emirates_id_front_image,
      national_id_back: emirates_id_back_image,
      profile_image: "",
      license_expiry: license_expiry_date,
      status: active,
      
    };
    // if (buy_through_type === "TOKEN") {
    //   data.required_tokens = required_tokens;
    // }
    const response = await add_supplier(data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Supplier Added Successfully", { variant: "success" });
      props.history.push("/suppliers");
    }


  };

  const TradeLicensefileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0])
    setLicense_File(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("image", e.target.files[0]);
    const upload_image_resp = await upload_image_s3(formData);
    console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
      enqueueSnackbar(upload_image_resp.message, { variant: "success" });
      setLicense_Image(upload_image_resp.path);
    } else {
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
    }
  };

  const emiratesIdFrontFileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0])
    setEmirates_Id_Front_File(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("image", e.target.files[0]);
    const upload_image_resp = await upload_image_s3(formData);
    console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
      enqueueSnackbar(upload_image_resp.message, { variant: "success" });
      setEmirates_Id_Front_Image(upload_image_resp.path);
    } else {
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
    }
  };

  const emiratesIdBackFileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0])
    setEmirates_Id_Back_File(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
    formData.append("image", e.target.files[0]);
    const upload_image_resp = await upload_image_s3(formData);
    console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
    if (upload_image_resp.code == 200) {
      setiswaiting(false);
      enqueueSnackbar(upload_image_resp.message, { variant: "success" });
      setEmirates_Id_Back_Image(upload_image_resp.path);
    } else {
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
    }
  };

  if (iswaiting) {
    return <CircularProgress style={{ paddingTop: "25%", paddingLeft: "50%" }} />
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          />
          <Avatar className={classes.avatar}>
            <SettingsIcon />
          </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Add Supplier</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >

              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Name"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"

                />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <TextField
                  required
                  id="outlined-multiline-static"
                  label="Email"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"

                />
              </Grid>

              {phone_number.map((x, i) => (
                <>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  
                <span
                  onClick={() => handleDeletePhoneNumber(i)}
                  className="float-right mr-2 pointer"
                >
                  <HighlightOffIcon />
                </span>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControl component="fieldset">
      <FormLabel component="legend">Type</FormLabel>
      <RadioGroup styele={{display: "flex", flexDirection: "row"}} aria-label="gender" name="gender1" value={x.type} onChange={(e)=> handlePhoneNumberChange(i, "type", e.target.value)}>
        <FormControlLabel value="office" control={<Radio color="primary" />} label="Office" />
        <FormControlLabel value="work" control={<Radio color="primary" />} label="Work" />
        <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
      </RadioGroup>
    </FormControl>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <TextField
                    required
                    id="outlined-multiline-static"
                    label="Phone Number"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="desc"
                    value={x.contact}
                    onChange={(e) => handlePhoneNumberChange(i, "contact", e.target.value)}
                    name="desc"

                  />
                </Grid>
                </>
               
              ))}
              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button
                  startIcon={<AddIcon />}
                  id="add-new-section"
                  onClick={handleAddPhoneNumber}
                  color="primary"
                  variant="contained"
                >
                  Add New Section
                </Button>
              </Grid>



              


              


             

              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <TextField
                  // required
                  id="outlined-multiline-static"
                  label="License Expiry Date"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  type="date"
                  autoFocus
                  value={license_expiry_date}
                  onChange={(e) => setLicense_Expiry_Date(e.target.value)}
                  name="license_expiry_date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                {license_file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={license_file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-license-file"
                  type="file"
                  onChange={TradeLicensefileChangedHandler}
                />
                <label htmlFor="contained-button-license-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Trade License Image
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {emirates_id_front_file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={emirates_id_front_file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-emirates-id-front-file"
                  type="file"
                  onChange={emiratesIdFrontFileChangedHandler}
                />
                <label htmlFor="contained-button-emirates-id-front-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Emirates ID Front Image
                  </Button>
                </label>
              </Grid> 

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {emirates_id_back_file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={emirates_id_back_file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-emirates-id-back-file"
                  type="file"
                  onChange={emiratesIdBackFileChangedHandler}
                />
                <label htmlFor="contained-button-emirates-id-back-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Emirates ID Back Image
                  </Button>
                </label>
              </Grid> 

              <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={(event) => setActive(event.target.checked)}
                      name="active"
                      color="primary"
                    />
                  }
                  label="Status"
                />
              </Grid>


              <Grid item xs={12}
                style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Draw
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
