import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import { add_promo_code } from "../../DAL/promo_code";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Add_PromoCode(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [token, setToken] = useState("");
  const [limit, setLimit] = useState("");
  const [status, setStatus] = useState(true);
  const [expire_date, setEpriry_Date] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    
    const data = {
      name,
      expire_date,
      token,
      limit,
      status,
    };
    const response = await add_promo_code(data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Promo Code Added Successfully", { variant: "success" });
      props.history.push("/promo_code");
    }
  
  };

  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }
  


  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Add Promo Code</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
            <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e)=> setName(e.target.value)}
          name="name"

        />
         </Grid>

         {/* <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Code"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={code}
          onChange={(e)=> setCode(e.target.value)}
          name="promo code"
          // multiline
          // rows={4}
          // maxRows={6}
        />
         </Grid> */}

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Tokens"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="text"
          value={token}
          onChange={(e)=> setToken(e.target.value)}
          name="token"

        />
         </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Limit"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="text"
          value={limit}
          onChange={(e)=> setLimit(e.target.value)}
          name="limit"

        />
         </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Code Expiry Date"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="date"
          autoFocus
          value={expire_date}
          onChange={(e)=> setEpriry_Date(e.target.value)}
          name="code_closing_date"

        />
         </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={status}
            onChange={(event)=> setStatus(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid> 
             

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Promo Code
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
