import Pdf from 'react-to-pdf';
import { useRef } from 'react';
import { color_palette } from '../../../theme/theme';

export default function TicketPDF(props) {
	const ref = useRef();
console.log(props.location.state,"props, ================")
	return (
		<div className="App">
			<Pdf targetRef={ref} filename={`${props.location.state[0].prize.name+"-"+"draw"+"-"+"tickets.pdf"}`}>
				{({ toPdf }) => (
					<button onClick={toPdf} className="button">
						Generate PDF
					</button>
				)}
			</Pdf>
			<div ref={ref}>
                <h1 className='text-center'>Draw Tickets</h1>
                {props.location.state.map((x,i)=>(
                    <div className='mt-4'>
                    <hr></hr>
                    <div className='row pl-3 pr-3'>
                    <div className='col-4 p-3'>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h6>Ticket No.</h6>
                        <p>{x.ticket_no}</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h6>Name.</h6>
                        <p>{x.user_detail.name}</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h6>Draw.</h6>
                        <p>{x.prize.name}</p>
                        </div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                        <h6>Date.</h6>
                        <p>{x.createdAt?.substring(0,10)}</p>
                        </div>
                    </div>
                    <div className='col-8' style={{backgroundColor: color_palette.primary}}></div>
                </div>
                </div>
                ))}
            </div>
		</div>
	);
}