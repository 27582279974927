import React from "react";
import { Switch, Redirect } from "react-router-dom";
import {
  PrivateRouteWithLayout,
  // RouteWithLayout,
  PublicRouteWithLayout,
  // PrivateRouteWithLayout,
} from "../components";
import { MainWithSidebar, MinimalLayout } from "../layouts";

import { Dashboard, Login, ChangePassword, Setting, Users,
  Transactions,
  TransactionDetails,
  Draws_Listing,
  Add_Draw,
  Edit_Draw,
  Country_Listing,
  Add_Country,
  Edit_Country,
  Tokens_Listing,
  Add_Token,
  Edit_Token,
  PremiumCoupons_Listing,
  Add_PremiumCoupons,
  Edit_PremiumCoupons,
  Products_Listing,
  Add_Product,
  Edit_Product,
  OrderHistory,
  PromoCode_Listing,
  Add_PromoCode,
  Edit_PromoCode,
  Tickets_Listing,
  TicketPDF,
  Coupons_Tickets_Listing,
  CouponsTicketPDF,
  UserDetail,
  RedeemHistory,
  DeleteAccount,
  Order_Detail_Tickets_Listing,
  Suppliers_Listing,
  Add_Supplier,
  Edit_Supplier,
  Supplier_Branches_Listing,
  Add_Branch,
  Edit_Branch,
  Settings,
  Banners_Listing,
  Add_Banner,
  Edit_Banner,
 } from "../pages";
function Routes() {
  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <PublicRouteWithLayout
          component={Login}
          exact
          layout={MinimalLayout}
          path="/login"
        />
        <PublicRouteWithLayout
          component={DeleteAccount}
          exact
          layout={MinimalLayout}
          path="/delete_account"
        />
        {/* --------------------------------------- Dashboard --------------------------------------- */}
        <PrivateRouteWithLayout
          component={Dashboard}
          exact
          layout={MainWithSidebar}
          path="/dashboard"
        />
        {/* --------------------------------------- Change Password --------------------------------------- */}
        <PrivateRouteWithLayout
          component={ChangePassword}
          exact
          layout={MainWithSidebar}
          path="/change-password"
        />

        {/* --------------------------------------- Setting --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Setting}
          exact
          layout={MainWithSidebar}
          path="/Setting"
        />
        {/* --------------------------------------- users--------------------------------------- */}
        <PrivateRouteWithLayout
          component={Users}
          exact
          layout={MainWithSidebar}
          path="/users"
        />
        <PrivateRouteWithLayout
          component={UserDetail}
          exact
          layout={MainWithSidebar}
          path="/users/detail/:id"
        />

        {/* --------------------------------------- Suppliers --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Suppliers_Listing}
          exact
          layout={MainWithSidebar}
          path="/suppliers"
        />
         <PrivateRouteWithLayout
          component={Add_Supplier}
          exact
          layout={MainWithSidebar}
          path="/suppliers/add_supplier"
        />
        <PrivateRouteWithLayout
          component={Edit_Supplier}
          exact
          layout={MainWithSidebar}
          path="/suppliers/edit_supplier/:id"
        />

<PrivateRouteWithLayout
          component={Supplier_Branches_Listing}
          exact
          layout={MainWithSidebar}
          path="/suppliers/branches/:id"
        />
         <PrivateRouteWithLayout
          component={Add_Branch}
          exact
          layout={MainWithSidebar}
          path="/suppliers/add_branch"
        />
        <PrivateRouteWithLayout
          component={Edit_Branch}
          exact
          layout={MainWithSidebar}
          path="/suppliers/edit_branch/:id"
        />

         {/* --------------------------------------- transaction--------------------------------------- */}
         <PrivateRouteWithLayout
          component={Transactions}
          exact
          layout={MainWithSidebar}
          path="/transactions"
        />
        <PrivateRouteWithLayout
          component={TransactionDetails}
          exact
          layout={MainWithSidebar}
          path="/transactions/transaction_detail/:id"
        />
         {/* --------------------------------------- draws --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Draws_Listing}
          exact
          layout={MainWithSidebar}
          path="/draws"
        />
        <PrivateRouteWithLayout
          component={Tickets_Listing}
          exact
          layout={MainWithSidebar}
          path="/draws/tickets/:id"
        />
        <PrivateRouteWithLayout
          component={TicketPDF}
          exact
          layout={MainWithSidebar}
          path="/draws/print_tickets"
        />
         <PrivateRouteWithLayout
          component={Add_Draw}
          exact
          layout={MainWithSidebar}
          path="/draws/add_draw"
        />
        <PrivateRouteWithLayout
          component={Edit_Draw}
          exact
          layout={MainWithSidebar}
          path="/draws/edit_draw/:id"
        />
        <PrivateRouteWithLayout
          component={Country_Listing}
          exact
          layout={MainWithSidebar}
          path="/countries"
        /> 
        <PrivateRouteWithLayout
          component={Add_Country}
          exact
          layout={MainWithSidebar}
          path="/countries/add_country"
        />  
        <PrivateRouteWithLayout
          component={Edit_Country}
          exact
          layout={MainWithSidebar}
          path="/countries/edit_country/:id"
        />  
                 {/* --------------------------------------- token --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Tokens_Listing}
          exact
          layout={MainWithSidebar}
          path="/tokens"
        />
        <PrivateRouteWithLayout
          component={Add_Token}
          exact
          layout={MainWithSidebar}
          path="/tokens/add_token"
        />
        <PrivateRouteWithLayout
          component={Edit_Token}
          exact
          layout={MainWithSidebar}
          path="/tokens/edit_token/:id"
        />
         {/* --------------------------------------- promocode --------------------------------------- */}

<PrivateRouteWithLayout
          component={PromoCode_Listing}
          exact
          layout={MainWithSidebar}
          path="/promo_code"
        />
        <PrivateRouteWithLayout
          component={Add_PromoCode}
          exact
          layout={MainWithSidebar}
          path="/promo_code/add_promo_code"
        />
        <PrivateRouteWithLayout
          component={Edit_PromoCode}
          exact
          layout={MainWithSidebar}
          path="/promo_code/edit_promo_code/:id"
        />
        <PrivateRouteWithLayout
          component={PremiumCoupons_Listing}
          exact
          layout={MainWithSidebar}
          path="/premium_coupons"
        /> 
        <PrivateRouteWithLayout
          component={Add_PremiumCoupons}
          exact
          layout={MainWithSidebar}
          path="/premium_coupons/add_premium_coupons"
        /> 
        <PrivateRouteWithLayout
          component={Edit_PremiumCoupons}
          exact
          layout={MainWithSidebar}
          path="/premium_coupons/edit_premium_coupons/:id"
        /> 
        <PrivateRouteWithLayout
          component={Coupons_Tickets_Listing}
          exact
          layout={MainWithSidebar}
          path="/premium_coupons/coupons_tickets/:id"
        />
        <PrivateRouteWithLayout
          component={CouponsTicketPDF}
          exact
          layout={MainWithSidebar}
          path="/premium_coupons/print_coupons_tickets"
        />
        <PrivateRouteWithLayout
          component={Products_Listing}
          exact
          layout={MainWithSidebar}
          path="/product"
        /> 
        <PrivateRouteWithLayout
          component={Add_Product}
          exact
          layout={MainWithSidebar}
          path="/product/add_product"
        /> 
         <PrivateRouteWithLayout
          component={Edit_Product}
          exact
          layout={MainWithSidebar}
          path="/product/edit_product/:id"
        /> 

        {/* --------------------------------------- order history--------------------------------------- */}
        <PrivateRouteWithLayout
          component={OrderHistory}
          exact
          layout={MainWithSidebar}
          path="/order_history"
        />

<PrivateRouteWithLayout
          component={Order_Detail_Tickets_Listing}
          exact
          layout={MainWithSidebar}
          path="/order_history/order_detail_tickets/:id"
        />

        {/* --------------------------------------- Redeem history--------------------------------------- */}
        <PrivateRouteWithLayout
          component={RedeemHistory}
          exact
          layout={MainWithSidebar}
          path="/redeem_history"
        />

        {/* --------------------------------------- draws --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Banners_Listing}
          exact
          layout={MainWithSidebar}
          path="/banners"
        />
         <PrivateRouteWithLayout
          component={Add_Banner}
          exact
          layout={MainWithSidebar}
          path="/banners/add_banner"
        />
        <PrivateRouteWithLayout
          component={Edit_Banner}
          exact
          layout={MainWithSidebar}
          path="/banners/edit_banner/:id"
        />

        {/* --------------------------------------- Setting --------------------------------------- */}

        <PrivateRouteWithLayout
          component={Settings}
          exact
          layout={MainWithSidebar}
          path="/Settings"
        />
  
      </Switch>
    </>
  );
}

export default Routes;
