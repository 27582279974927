import { invokeApi } from "../../bl_libs/invokeApi";

export const order_history_list = async (to_date, from_date, order_option, order_status, product_id, prize_id) => {
  let requestObj = {
    path: `api/order/get_all_orders`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      to_date,
      from_date,
      order_option,
      order_status,
      prize_id,
      product_id
    },
  };
  return invokeApi(requestObj);
};

export const change_order_status = async (data, id) => {
  let requestObj = {
    path: `api/order/change_order_status/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const order_detail_tickets = async (id) => {
  let requestObj = {
    path: `api/order/get_order_tickets/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};