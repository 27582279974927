import { invokeApi } from "../../bl_libs/invokeApi";

export const user_list = (page, limit) => {
  let requestObj = {
    path: `api/customer/get_customers`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page,
      limit: limit
    },
  };
  return invokeApi(requestObj);
};

export const send_users_notifications = (data) => {
  let requestObj = {
    path: `api/admin/send_notification`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const change_influencer_status = (id, data) => {
  let requestObj = {
    path: `api/customer/change_influencer_status/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const user_reports = (page) => {
  let requestObj = {
    path: "admin/v1/all_reports",
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      page: page
    },
  };
  return invokeApi(requestObj);
};

export const user_report_reply = (id) => {
  let requestObj = {
    path: `admin/get_a_report/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const user_report_editreply = (id,data) => {
  let requestObj = {
    path: `admin/reply_to_report/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const user_search = async (search_text,page) => {
  let requestObj = {
    path: `admin/v1/user_search?page=${page}&full_name=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const user_reports_search = (search_text,page) => {
  let requestObj = {
    path: `admin/v1/search_report?page=${page}&report=${search_text}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
