import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import { add_draw } from "../../DAL/draws/draws";
import { upload_image_s3 } from "../../DAL/country/country";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Add_Draw(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [arabic_name, setArabic_Name] = useState("");
  const [arabic_description, setArabic_Description] = useState("");
  const [ticket_title, setTicket_Title] = useState("");
  const [buy_through_type, setBuy_Through_Type] = useState("");
  const [required_tokens, setRequired_Tokens] = useState(0);
  const [total_ticket_limitation, setTotal_Ticket_Limitation] = useState("");
  const [minimum_ticket_limitation, setMinimum_Ticket_Limitation] = useState("");
  const [draw_closing_date, setDraw_Closing_Date] = useState();
  const [active, setActive] = useState(true);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [ticketImage, setTicketImage] = useState("");
  const [ticketFile, setTicketFile] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    
    const data = {
      name,
      arabic_name,
      description,
      arabic_description,
      ticket_title,
      buy_through_type,
      total_ticket_limitation,
      minimum_ticket_limitation,
      ticket_closing_date: draw_closing_date,
      active,
      image,
      ticket_image: ticketImage,
    };
    if(buy_through_type === "TOKEN"){
      data.required_tokens = required_tokens;
    }
    const response = await add_draw(data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Draw Added Successfully", { variant: "success" });
      props.history.push("/draws");
    }
    
  
  };

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setFile(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setImage(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };

   const fileTicketChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setTicketFile(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setTicketImage(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };
  
  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }
  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Add Draw</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
            <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e)=> setName(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Arabic Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={arabic_name}
          onChange={(e)=> setArabic_Name(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="desc"
          value={description}
          onChange={(e)=> setDescription(e.target.value)}
          name="desc"

        />
        </Grid>

        <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Arabic Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={arabic_description}
          onChange={(e)=> setArabic_Description(e.target.value)}
          name="desc"

        />
        </Grid>

        <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Ticket Title"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={ticket_title}
          onChange={(e)=> setTicket_Title(e.target.value)}
          name="ticket_title"

        />
        </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">Buy Type</InputLabel>
        <Select
          native
          value={buy_through_type}
          onChange={(e)=> setBuy_Through_Type(e.target.value)}
          label="Buy Type"
          inputProps={{
            name: 'buy_through_type',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value="TOKEN">Prime</option>
          <option value="PRODUCT">Promotional</option>
        </Select>
      </FormControl>
          </Grid>

          {buy_through_type === "TOKEN" && <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Required Tokens"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="number"
          value={required_tokens}
          onChange={(e)=> setRequired_Tokens(e.target.value)}
          name="required_tokens"

        />
         </Grid>}

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Ticket Limitation"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="text"
          value={total_ticket_limitation}
          onChange={(e)=> setTotal_Ticket_Limitation(e.target.value)}
          name="total_ticket_limitation"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Minimum Ticket Limitation"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="text"
          value={minimum_ticket_limitation}
          onChange={(e)=> setMinimum_Ticket_Limitation(e.target.value)}
          name="minimum_ticket_limitation"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              // required
          id="outlined-multiline-static"
          label="Draw Closing Date"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="date"
          autoFocus
          value={draw_closing_date}
          onChange={(e)=> setDraw_Closing_Date(e.target.value)}
          name="draw_closing_date"
          InputLabelProps={{
            shrink: true,
          }}
        />
         </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid> 

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {ticketFile ? (
                  <>
                    {" "}
                    <img className={classes.img} src={ticketFile} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-ticket-file"
                  type="file"
                  onChange={fileTicketChangedHandler}
                />
                <label htmlFor="contained-button-ticket-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Ticket Image
                  </Button>
                </label>
              </Grid> 

          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={(event)=> setActive(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid> 
             

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Draw
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
