import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { color_palette } from "../../theme/theme";
// import { clients_list, Customer_search } from "../../DAL/country/clients";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
import { transaction_list } from "../../DAL/transaction/transaction";
import moment from "moment";
import { redeem_history_list } from "../../DAL/redeem_history";

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function RedeemHistory(props) {
  const classes = useStyles();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [isdata, setisdata] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalpage] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Order no.</span>,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.token_transaction_type === "prime_deal" ? "Token Deal" : "Premium Deal"}
          </div>
        </>
      ),
      sorting: false,
    },
    {
        title: <span className={classes.hed}> Customer Name</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.user_id?.customer_id?.name}
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Customer Email</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.user_id?.email}
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Customer Phone Number</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.user_id?.phone_number}
            </div>
          </>
        ),
        sorting: false,
      },
    {
      title: <span className={classes.hed}> Draw Name</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.prime_deal?.name}
            {/* {rowData.product[0]?.name ? rowData.product[0]?.name : "---"} */}
          </div>
        </>
      ),
      sorting: false,
    },
    {
        title: <span className={classes.hed}> Draw Ref. no</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.prime_deal?.reference_no}
              {/* {rowData.product[0]?.name ? rowData.product[0]?.name : "---"} */}
            </div>
          </>
        ),
        sorting: false,
      },

      {
        title: <span className={classes.hed}> Draw Token</span>,
       field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.prime_deal?.required_tokens}
              {/* {rowData.product[0]?.name ? rowData.product[0]?.name : "---"} */}
            </div>
          </>
        ),
        sorting: false,
      },
    {
      title: <span className={classes.hed}> Redeem Date</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {moment(rowData.createdAt).format("DD MMM YYYY hh:mm:sa")}
          </div>
        </>
      ),
      sorting: false,
    },
    
  ]);

  const hanldeSearchChange = async (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(false);
    // const data_search = await Customer_search(
    //   page,
    //   rowsPerPage,
    //   e.target.value
    // );
    // console.log(data_search, "DATA SEARCH");
    // if (data_search.code == 200) {
    //   if (data_search.data.customer.length != 0) {
    //     setData(data_search.data.customer);
    //     setTotalpage(data_search.data.total_pages);
    //     setPage(0);
    //   }
    //   setisdata(true);
    // }
  };
  const Addcategory = () => {
    props.history.push("/Addcategory");
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(false);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(false);
    setRowsPerPage(parseInt(event.target.value));
  };
  const deleteuser = async () => {
    // setOpen(false);
    // setData([]);
    // setisdata(false);
    // const delete_category_resp = await delete_category(selectedrow._id);
    // await get_category(page);
  };
  const get_Transaction = async () => {
    const redeemed_list_resp = await redeem_history_list();
    if(redeemed_list_resp.code === 200){
      console.log(redeemed_list_resp, "orders_list_resp");
      setTotalpage(redeemed_list_resp.transactions.length);
      setData(redeemed_list_resp.transactions);
      setusers(false);
      setisdata(false);
    }
  };
  useEffect(() => {
    get_Transaction(page, rowsPerPage);
  }, [rowsPerPage, page]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <MaterialTable
            tableRef={tableRef}
            onSearchChange={(e) => console.log("search changed: " + e)}
            style={{ marginTop: "50px" }}
            title="Redeem History"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            // components={{
            //   Pagination: (props) => (
            //     <TablePagination
            //       component="div"
            //       count={totalPage}
            //       rowsPerPageOptions={[10, 20, 30, 40]}
            //       page={page}
            //       onChangePage={handleChangePage}
            //       rowsPerPage={rowsPerPage}
            //       onChangeRowsPerPage={handleChangeRowsPerPage}
            //     />
            //   ),
            //   Toolbar: (props) => (
            //     <div>
            //       <Box display="flex" p={1}>
            //         <Box p={1} flexGrow={1}>
            //           <h3>Transactions</h3>
            //         </Box>
            //         <Box p={1}>
            //           <TextField
            //             id="input-with-icon-textfield"
            //             value={search}
            //             focused
            //             autoFocus={true}
            //             onChange={hanldeSearchChange}
            //             placeholder="Search"
            //             InputProps={{
            //               startAdornment: (
            //                 <InputAdornment
            //                   position="start"
            //                   style={{ float: "right" }}
            //                 >
            //                   <SearchIcon />
            //                 </InputAdornment>
            //               ),
            //             }}
            //           />
            //         </Box>
            //       </Box>
            //     </div>
            //   ),
            // }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == true ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            options={{
              actionsColumnIndex: -1,
              search: true,
              // pageSize: rowsPerPage,
              pageSize: 10,
              pageSizeOptions: [10,20,30,40,50],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#007044",
                color: color_palette.secondary,
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.title}`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deleteuser} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(RedeemHistory);
