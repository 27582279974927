import { invokeApi } from "../../bl_libs/invokeApi";

export const suppliers_list = async () => {
  let requestObj = {
    path: `api/supplier/all_supplier_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page,limit,search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_supplier = (data) => {
  let requestObj = {
    path: `api/supplier/add_supplier`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_supplier = (id,data) => {
  let requestObj = {
    path: `api/supplier/edit_supplier/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const supplier_detail = (id) => {
  let requestObj = {
    path: `api/supplier/supplier_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_suppliers_list = async () => {
    let requestObj = {
      path: `api/supplier/active_supplier_list`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };


  //////////////////////////////////// supplier branches api ///////////////


  export const supplier_branches_list = async () => {
    let requestObj = {
      path: `api/supplier/all_supplier_list`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };
  
  export const add_supplier_branch = (data, id) => {
    let requestObj = {
      path: `api/supplier/add_supplier_branch?supplier_id=${id}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
  
  export const edit_supplier_branch = (data, supplier_id, branch_id) => {
    let requestObj = {
      path: `api/supplier/edit_supplier_branch/?supplier_id=${supplier_id}&branch_id=${branch_id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };
