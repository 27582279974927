import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddIcon from "@material-ui/icons/Add";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { withRouter } from "react-router-dom";
import {
  Avatar,
  FormControlLabel,
  Switch,
  FormControl,
  IconButton,
  InputLabel,
  Select,
} from "@material-ui/core";
import { color_palette } from "../../theme/theme";
import SettingsIcon from "@material-ui/icons/Settings";
import EditIcon from "@material-ui/icons/Edit";
import { Editor } from "../../components";
import { AddSettings, GetSettings } from "../../DAL/settings/settings";
import { upload_image_s3 } from "../../DAL/country/country";
import { s3baseUrl } from "../../config/config";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: color_palette.primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  txt: {
    color: "black",
  },
  alert: {
    width: "100%",
  },
  swtch: {
    marginTop: "20px",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function Settings(props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [inputs, setInputs] = useState({
    support_email: "",
    referral_tokens: 0,
    meta_title: "",
    meta_description: "",
    meta_tags: "",
    version_number: "",
    terms_and_conditions: "",
    privacy_policy: "",
  });
  const [promotion_status, setPromotion_Status] = useState(false) 
  const [promotion_image, setPromotion_Image] = useState("") 
  const [promotion_image_file, setPromotion_Image_File] = useState("") 
  const [discount_status, setDiscount_Status] = useState(false) 
  const [discount_array, setDiscount_Array] = useState(
    [{ count: 0, discount: 0 }]
  ) 
  const [isDisable, setIsDisable] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleChangeInCkEdditor = (key, value) => {
    setInputs({
      ...inputs,
      [key]: value,
    });
  };

  const handleAddSection = () => {
    setDiscount_Array((discount) => [
      ...discount,
      {
        count: 0, discount: 0
      },
    ]);
  };

  const handleDeleteSection = (id) => {
    setDiscount_Array((section) => section.filter((sec,index) => index !== id));
  };

  const handleChangeDiscountArray = (id, key, value) => {
    setDiscount_Array((section) =>
      section.map((sec, index) => {
        console.log([key],value,"value")
        if (index === id) {
          return {
            ...sec,
            [key]: value,
          };
        } else {
          return sec;
        }
      })
    );
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(inputs);
    if (isDisable === false) {
      setWaiting(true);
      UpdateSettings(e);
    } else {
      setIsDisable(false);
    }
  };

  const UpdateSettings = async (event) => {
    event.preventDefault();
    const postData = {
      // support_email: inputs.support_email,
      meta_title: inputs.meta_title,
      meta_description: inputs.meta_description,
      meta_tags: inputs.meta_tags,
      referral_tokens: inputs.referral_tokens,
      version_number: inputs.version_number,
      promotion_status: promotion_status,
      promotion_image: promotion_image,
      discount_status: discount_status,
      discount_array: discount_array
      // privacy_policy: inputs.privacy_policy,
      // terms_and_conditions: inputs.terms_and_conditions,
    };
    setWaiting(true);

    const result = await AddSettings(postData);
    if (result.code === 200) {
      //  setIsDisable(true);
      FetchSettings();
      setWaiting(false);
    } else {
      setWaiting(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.id]: e.target.value,
    });
  };

  const fileChangedHandler = async (e) => {
    setWaiting(true);
     console.log(e.target.files[0])
     setPromotion_Image_File(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
      setWaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setPromotion_Image(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };

  const FetchSettings = async () => {
    setWaiting(true);
    const result = await GetSettings();
    if (result.code === 200) {
      // console.log(result, "==============result")
      const obj = {
        meta_title: result?.setting?.meta_title,
        meta_description: result?.setting?.meta_description,
        meta_tags: result?.setting?.meta_tags,
        referral_tokens: result?.setting?.referral_tokens,
        version_number: result?.setting?.version_number,
        terms_and_conditions: "",
        privacy_policy: "",
      };
      setDiscount_Array(result?.setting?.discount_array)
      setDiscount_Status(result?.setting?.discount_status)
      setPromotion_Status(result?.setting?.promotion_status)
      setPromotion_Image_File(s3baseUrl + result?.setting?.promotion_image)
      console.log(obj, "==============obj")
      setInputs(obj);
      setWaiting(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    FetchSettings();
  }, []);

  if (waiting) {
    return <CircularProgress className={classes.loading} />;
  }

  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />

        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <SettingsIcon />
          </Avatar>
          <Typography className={classes.txt} component="h1" variant="h5">
            Settings
          </Typography>

          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="email"
                  fullWidth
                  id="support_email"
                  value={inputs.support_email}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="Support email"
                  name="support_email"
                  autoComplete="off"
                  autoFocus
                />
              </Grid> */}

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                  id="meta_title"
                  value={inputs.meta_title}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="Meta Title"
                  name="meta_title"
                  autoComplete="off"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                  id="meta_description"
                  value={inputs.meta_description}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="Meta Description"
                  name="meta_description"
                  autoComplete="off"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  type="text"
                  fullWidth
                  id="meta_tags"
                  value={inputs.meta_tags}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="Meta Tags (Keywords)"
                  name="meta_tags"
                  autoComplete="off"
                  autoFocus
                />
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  type="number"
                  fullWidth
                  id="referral_tokens"
                  value={inputs.referral_tokens}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="Refferal Token"
                  name="referral_tokens"
                />
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  type="text"
                  fullWidth
                  id="version_number"
                  value={inputs.version_number}
                  onChange={handleChange}
                  disabled={isDisable}
                  label="App Version Number"
                  name="version_number"
                />
              </Grid>


              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={promotion_status}
            onChange={(event)=> setPromotion_Status(event.target.checked)}
            name="promotion_status"
            color="primary"
          />
        }
        label="Promotion Status"
      />
         </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {promotion_image_file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={promotion_image_file} />{" "}
                  </>
                ) : null}
              </Grid>

             {promotion_status &&
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Promotion Image
                  </Button>
                </label>
              </Grid> 
              }


<Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={discount_status}
            onChange={(event)=> setDiscount_Status(event.target.checked)}
            name="discount_status"
            color="primary"
          />
        }
        label="Discount Status"
      />
         </Grid>

         {discount_array.map((x,i)=>(
          <>

          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
          <span
                  onClick={() => handleDeleteSection(i)}
                  className="float-right mr-2 pointer"
                >
          <HighlightOffIcon />
          </span>
          </Grid>
          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  type="number"
                  fullWidth
                  id="count"
                  value={x.count}
                  onChange={(e) =>
                    handleChangeDiscountArray(i, "count", e.target.value)
                  }
                  disabled={isDisable}
                  label="Count"
                  name="count"
                />
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                type="number"
                fullWidth
                id="discount"
                value={x.discount}
                onChange={(e) =>
                  handleChangeDiscountArray(i, "discount", e.target.value)
                }
                disabled={isDisable}
                label="Discount"
                name="discount"
              />
            </Grid>
            </>
         ))}

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={()=> handleAddSection()}
                  >
                   Add <AddIcon />
                  </Button>
              </Grid> 

              

           

              {/* <Grid item xs={12}>
                <Typography color="textSecondary">
                  Terms and conditions:
                </Typography>
                <Editor
                  content={inputs.terms_and_conditions}
                  setContent={(value) => {
                    handleChangeInCkEdditor("terms_and_conditions", value);
                  }}
                  mode={isDisable}
                />
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Typography color="textSecondary">Privacy Policy :</Typography>
                <Editor
                  content={inputs.privacy_policy}
                  setContent={(value) => {
                    handleChangeInCkEdditor("privacy_policy", value);
                  }}
                  mode={isDisable}
                />
              </Grid> */}
            </Grid>

            <Grid item xs={12}>
              {isDisable ? (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.submit}
                  startIcon={<EditIcon />}
                  fullWidth
                >
                  Edit settings
                </Button>
              ) : (
                <Button
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update Settings
                </Button>
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
export default withRouter(Settings);
