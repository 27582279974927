import { invokeApi } from "../../bl_libs/invokeApi";

export const promo_code_list = async () => {
  let requestObj = {
    path: `api/promo_code/all_promo_code_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page,limit,search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_promo_code = (data) => {
  let requestObj = {
    path: `api/promo_code/add_promo_code`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_promo_code = (id,data) => {
  let requestObj = {
    path: `api/promo_code/edit_promo_code/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const promo_code_detail = (id) => {
  let requestObj = {
    path: `api/promo_code/promo_code_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
