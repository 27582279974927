import React from "react";
import { useEffect } from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { withRouter } from "react-router";
import SettingsIcon from "@material-ui/icons/Settings";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import TheatersIcon from '@material-ui/icons/Theaters';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import ListIcon from '@material-ui/icons/List';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import GestureIcon from '@material-ui/icons/Gesture';
import HistoryIcon from '@material-ui/icons/History';
import LinkIcon from '@material-ui/icons/Link';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import DialpadIcon from '@material-ui/icons/Dialpad';
import BusinessIcon from '@material-ui/icons/Business';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import BuildIcon from "@material-ui/icons/Build";
import { color_palette } from "../../../../theme/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
function SideMenu(props) {
  const { selectedIndex, handleListItemClick } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openmenu, setOpenMenu] = React.useState(false);
  const [openquotes, setOpenquotes] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickMenu = () => {
    setOpenMenu(!openmenu);
  };
  const handleClickquotes = () => {
    setOpenquotes(!openquotes);
  };
  useEffect(() => {
    const currentPath = props.history.location.pathname;
    if (currentPath.search("dashboard") > 0) {
      handleListItemClick("dashboard");
    } else if (currentPath.search("users") > 0) {
      handleListItemClick("users");
    } else if (currentPath.search("transactions") > 0) {
      handleListItemClick("transactions");
    } else if (currentPath.search("order_history") > 0) {
      handleListItemClick("order_history");
    } else if (currentPath.search("redeem_history") > 0) {
      handleListItemClick("redeem_history");
    } else if (currentPath.search("designs") > 0) {
      handleListItemClick("designs");
    } else if (currentPath.search("Setting") > 0) {
      handleListItemClick("Setting");
    } else if (currentPath.search("services") > 0) {
      handleListItemClick("services");
    } else if (currentPath.search("draws") > 0) {
      handleListItemClick("draws");
    } else if (currentPath.search("countries") > 0) {
      handleListItemClick("countries");
    } else if (currentPath.search("tokens") > 0) {
      handleListItemClick("tokens");
    } else if (currentPath.search("suppliers") > 0) {
      handleListItemClick("suppliers");
    } else if (currentPath.search("promo_code") > 0) {
      handleListItemClick("promo_code");
    } else if (currentPath.search("premium_coupons") > 0) {
      handleListItemClick("premium_coupons");
    } else if (currentPath.search("product") > 0) {
      handleListItemClick("product");
    } else if (currentPath.search("general_settings") > 0) {
      handleListItemClick("general_settings");
    } else if (currentPath.search("banners") > 0) {
      handleListItemClick("banners");
    } else {
      handleListItemClick("");
    }
  }, [selectedIndex, props.history.location.pathname]);
  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        {/* ------------------------------------------------------ Dashboard */}
        <ListItem
          style={
            selectedIndex === "dashboard"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "dashboard"}
          onClick={(event) => {
            handleListItemClick("dashboard");
            props.history.push("/dashboard");
          }}
          button
        >
          <ListItemIcon>
            <DashboardIcon
              style={
                selectedIndex === "dashboard"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {/* ------------------------------------------------------Countries   */}

         <ListItem button 
        //  className={classes.nested}
          style={
            selectedIndex === "countries"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "countries"}
          onClick={(event) => {
            handleListItemClick("countries");
            props.history.push("/countries");
          }}
          button
        >
          <ListItemIcon>
            <ViewAgendaIcon
              style={selectedIndex === "countries" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Countries" />
        </ListItem> 

        {/* ------------------------------------------------------ users */}
        <ListItem
          style={
            selectedIndex === "users"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "users"}
          onClick={(event) => {
            handleListItemClick("users");
            props.history.push("/users");
          }}
          button
        >
          <ListItemIcon>
            <AccountBoxIcon
              style={
                selectedIndex === "users"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>

         {/* ------------------------------------------------------tokens   */}

         <ListItem button 
        //  className={classes.nested}
          style={
            selectedIndex === "tokens"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "tokens"}
          onClick={(event) => {
            handleListItemClick("tokens");
            props.history.push("/tokens");
          }}
          button
        >
          <ListItemIcon>
            <ViewColumnIcon
              style={selectedIndex === "tokens" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Tokens" />
        </ListItem> 

        {/* ------------------------------------------------------suppliers   */}

        <ListItem button 
        //  className={classes.nested}
          style={
            selectedIndex === "suppliers"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "suppliers"}
          onClick={(event) => {
            handleListItemClick("suppliers");
            props.history.push("/suppliers");
          }}
          button
        >
          <ListItemIcon>
            <BusinessIcon
              style={selectedIndex === "suppliers" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Suppliers" />
        </ListItem> 

         {/* ------------------------------------------------------inventory management  */}

         <ListItem
          button onClick={handleClick}
          style={{fontSize: "0.85rem"}}
        >
          <ListItemIcon>
            <ShoppingBasketIcon
              style={selectedIndex === "Setting" ? { color: "#22787E" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Inventory" style={{fontSize: "0.85rem"}} />
          {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      
      <Collapse in={open} timeout="auto" unmountOnExit>

        <List >

         
           {/* ------------------------------------------------------premium coupons  */}

           {/* <ListItem button 
         className={classes.nested}
          style={
            selectedIndex === "premium_coupons"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "premium_coupons"}
          onClick={(event) => {
            handleListItemClick("premium_coupons");
            props.history.push("/premium_coupons");
          }}
          button
          >
            <ListItemIcon>
            <ListIcon
              style={selectedIndex === "premium_coupons" ? { color: "white" } : {}}
            />
            </ListItemIcon>
            <ListItemText primary="Premium Coupons" />
          </ListItem> */}

         {/* ------------------------------------------------------ product */}
         <ListItem
         className={classes.nested}
          style={
            selectedIndex === "product"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "product"}
          onClick={(event) => {
            handleListItemClick("product");
            props.history.push("/product");
          }}
          button
        >
          <ListItemIcon>
            <TheatersIcon
              style={
                selectedIndex === "product"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Product" />
        </ListItem>

        {/* ------------------------------------------------------Draws  */}

          <ListItem button 
          className={classes.nested}
          style={
            selectedIndex === "draws"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "draws"}
          onClick={(event) => {
            handleListItemClick("draws");
            props.history.push("/draws");
          }}
          button
        >
          <ListItemIcon>
            <GestureIcon
              style={selectedIndex === "draws" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Draws / Prizes" />
        </ListItem> 

        

        </List>
      </Collapse>

      {/* ------------------------------------------------------ promo code */}
      <ListItem
          style={
            selectedIndex === "promo_code"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "promo_code"}
          onClick={(event) => {
            handleListItemClick("promo_code");
            props.history.push("/promo_code");
          }}
          button
        >
          <ListItemIcon>
            <DialpadIcon
              style={
                selectedIndex === "promo_code"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Promo Codes" />
        </ListItem>

        

        {/* ------------------------------------------------------ order_history */}
        <ListItem
          style={
            selectedIndex === "order_history"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "order_history"}
          onClick={(event) => {
            handleListItemClick("order_history");
            props.history.push("/order_history");
          }}
          button
        >
          <ListItemIcon>
            <HistoryIcon
              style={
                selectedIndex === "order_history"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Order History" />
        </ListItem>

        {/* ------------------------------------------------------ redeem_history */}
        <ListItem
          style={
            selectedIndex === "redeem_history"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "redeem_history"}
          onClick={(event) => {
            handleListItemClick("redeem_history");
            props.history.push("/redeem_history");
          }}
          button
        >
          <ListItemIcon>
            <HistoryIcon
              style={
                selectedIndex === "redeem_history"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Redeem History" />
        </ListItem>

          {/* ------------------------------------------------------ transaction */}
        <ListItem
          style={
            selectedIndex === "transactions"
              ? {
                  backgroundColor: color_palette.primary,
                  color: color_palette.secondary,
                }
              : {}
          }
          selected={selectedIndex === "transactions"}
          onClick={(event) => {
            handleListItemClick("transactions");
            props.history.push("/transactions");
          }}
          button
        >
          <ListItemIcon>
            <AccountBalanceIcon
              style={
                selectedIndex === "transactions"
                  ? { color: color_palette.secondary }
                  : {}
              }
            />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItem>


         {/* ------------------------------------------------------ Setting  */}

         <ListItem button 
          style={
            selectedIndex === "Setting"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "Setting"}
          onClick={(event) => {
            handleListItemClick("Setting");
            props.history.push("/Setting");
          }}
          button
          >
            <ListItemIcon>
            <SettingsIcon
              style={selectedIndex === "Setting" ? { color: "white" } : {}}
            />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>

         {/* ------------------------------------------------------Banners  */}

         <ListItem button 
          style={
            selectedIndex === "banners"
              ? { backgroundColor: color_palette.primary, color: "white" }
              : {}
          }
          selected={selectedIndex === "banners"}
          onClick={(event) => {
            handleListItemClick("banners");
            props.history.push("/banners");
          }}
          button
        >
          <ListItemIcon>
            <GestureIcon
              style={selectedIndex === "banners" ? { color: "white" } : {}}
            />
          </ListItemIcon>
          <ListItemText primary="Banners" />
        </ListItem> 
        
      </List>
    </>
  );
}

export default withRouter(SideMenu);
