import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useParams } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControlLabel
} from "@material-ui/core";
import { edit_premium_coupon, premium_coupon_detail } from "../../DAL/premium_coupons";
import { s3baseUrl } from "../../config/config";
import { upload_image_s3 } from "../../DAL/country/country";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Edit_PremiumCoupons(props) {
  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [required_tokens, setRequired_Tokens] = useState(0);
  const [coupons_closing_date, setCoupons_Closing_Date] = useState()
  const [active, setActive] = useState(true);
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    const data = {
      name: title,
      description,
      required_tokens,
      coupon_closing_date: coupons_closing_date,
      active,
      image
    };
    const response = await edit_premium_coupon(id, data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Coupon Updated Successfully", { variant: "success" });
      props.history.push("/premium_coupons");
    }
  
  };
  useEffect(async()=>{
    const detail_coupon = await premium_coupon_detail(id);
    if(detail_coupon.code === 200){
      console.log(detail_coupon, "detail_coupon");
      setTitle(detail_coupon.premium_coupon.name);
      setDescription(detail_coupon.premium_coupon.description);
      setRequired_Tokens(detail_coupon.premium_coupon.required_tokens);
      setCoupons_Closing_Date(detail_coupon.premium_coupon.coupon_closing_date);
      setActive(detail_coupon.premium_coupon.active);
      setFile(s3baseUrl+detail_coupon.premium_coupon.image)
      setImage(detail_coupon.premium_coupon.image)
      setiswaiting(false);
    }
  },[])

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
    console.log(e.target.files[0])
    setFile(URL.createObjectURL(e.target.files[0]));
   let formData = new FormData(); //formdata object
    formData.append("image", e.target.files[0]);
    const upload_image_resp = await upload_image_s3(formData);
    console.log(upload_image_resp);
   //  setimage(upload_image_resp.path);
    if(upload_image_resp.code == 200){
      setiswaiting(false);
      enqueueSnackbar(upload_image_resp.message, { variant: "success" });
      setImage(upload_image_resp.path);
    }else{
     enqueueSnackbar(upload_image_resp.message, { variant: "error" });
    }
   };
  
  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }
  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Edit Premium Coupon</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
            <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Title"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="title"
          value={title}
          onChange={(e)=> setTitle(e.target.value)}
          name="title"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={description}
          onChange={(e)=> setDescription(e.target.value)}
          name="desc"
          multiline
          rows={4}
          maxRows={6}
        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Required Tokens"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="number"
          value={required_tokens}
          onChange={(e)=> setRequired_Tokens(e.target.value)}
          name="required_tokens"

        />
         </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              // required
          id="outlined-multiline-static"
          label="Coupon Closing Date"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="date"
          value={coupons_closing_date?.substring(0,10)}
          onChange={(e)=> setCoupons_Closing_Date(e.target.value)}
          name="coupons_closing_date"
          InputLabelProps={{
            shrink: true,
          }}
        />
         </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid> 

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={(event)=> setActive(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid> 
             

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Edit Left Menu
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
