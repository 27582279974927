import { invokeApi } from "../../bl_libs/invokeApi";

export const products_list = async () => {
  let requestObj = {
    path: `api/product/all_product_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page,limit,search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_product = (data) => {
  let requestObj = {
    path: `api/product/add_product`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_product = (id,data) => {
  let requestObj = {
    path: `api/product/edit_product/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const product_detail = (id) => {
  let requestObj = {
    path: `api/product/product_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_draws_list = async () => {
    let requestObj = {
      path: `api/prize/active_promotional_prize_list`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };