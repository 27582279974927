import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControlLabel,
  FormControl,

} from "@material-ui/core";
import { add_country } from "../../DAL/country/country";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Add_Country(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [country_code, setCountry_Code] = useState("");
  const [currency, setCurrency] = useState("");
  const [currency_symbol, setCurrency_Symbol] = useState("");
  const [enable_vat, setEnable_Vat] = useState(false);
  const [vat_type, setVat_Type] = useState("")
  const [vat_percentage, setVat_Percentage] = useState()
  const [active, setActive] = useState(true)
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    
    const data = {
      name,
      code,
      country_code,
      currency,
      currency_symbol,
      active
    };
    if(enable_vat){
      data.enable_vat = enable_vat;
      data.vat_type = vat_type;
      data.vat_percentage = vat_percentage;
    }else{
      data.enable_vat = enable_vat;
    }
    const response = await add_country(data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Country Added Successfully", { variant: "success" });
      props.history.push("/countries");
    }
  
  };
  
  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }

  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Add Country</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="name"
          value={name}
          onChange={(e)=> setName(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Code"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="code"
          value={code}
          onChange={(e)=> setCode(e.target.value)}
          name="code"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Country Code"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="country_code"
          value={country_code}
          onChange={(e)=> setCountry_Code(e.target.value)}
          name="country_code"

        />
         </Grid>


         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Currency"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="currency"
          value={currency}
          onChange={(e)=> setCurrency(e.target.value)}
          name="currency"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Currency Symbol"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="currency_symbol"
          value={currency_symbol}
          onChange={(e)=> setCurrency_Symbol(e.target.value)}
          name="currency_symbol"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={enable_vat}
            onChange={(event)=> setEnable_Vat(event.target.checked)}
            name="enabled_vat"
            color="primary"
          />
        }
        label="Enabled VAT"
      />
         </Grid> 

        {enable_vat &&
        <>
         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">VAT Type</InputLabel>
        <Select
          native
          value={vat_type}
          onChange={(e)=> setVat_Type(e.target.value)}
          label="VAT Type"
          inputProps={{
            name: 'vat_type',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value="TAX">TAX</option>
          <option value="VAT">VAT</option>
        </Select>
      </FormControl>
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="VAT Percentage"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="vat_percentage"
          value={vat_percentage}
          onChange={(e)=> setVat_Percentage(e.target.value)}
          name="vat_percentage"

        />
         </Grid>
         </>
         }

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={(event)=> setActive(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid>        

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Country
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
