import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useSnackbar } from "notistack";
import { color_palette } from "../../theme/theme";
// import { clients_list, Customer_search } from "../../DAL/country/clients";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { draws_list, make_prize_winner, open_diamond_golden_promotions, prize_tickets_user_list, set_extra_tickets, } from "../../DAL/draws/draws";
import { DialogContent, FormControl, Select } from "@material-ui/core";

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function Draws_Listing(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [ticketsUser, setTicketsUsers] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [isdata, setisdata] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openMakeWinnerModal, setOpenMakeWinnerModal] = React.useState(false);
  const [selectedWinner, setSelectedWinner] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalpage] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [openPromotion, setOpenPromotion] = React.useState(false);
  const [promotion_date, setPromotion_Date] = React.useState(new Date());
  const [promotion_status, setPromotion_Status] = React.useState(false);


  const [openExtraTicket, setOpenExtraTicket] = React.useState(false);
  const [extra_ticket_status, setExtra_Ticket_Status] = React.useState(false);
  const [normal_delivery_tokens, setNormal_Delivery_Tokens] = React.useState(0);
  const [normal_delivery_tickets, setNormal_Delivery_Tickets] = React.useState(0);
  const [fast_delivery_tokens, setFast_Delivery_Tokens] = React.useState(0);
  const [fast_delivery_tickets, setFast_Delivery_Tickets] = React.useState(0);
  const [pick_up_tokens, setPick_Up_Tokens] = React.useState(0);
  const [pick_up_tickets, setPick_Up_Tickets] = React.useState(0);
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Title</span>,
      field: "name",
      // render: (rowData) => (
      //   <>
      //     <div style={{ minWidth: 100 }}>
      //       {rowData.name}
      //     </div>
      //   </>
      // ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Image</span>,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            <img style={{ width: 50, height: 70 }} src={s3baseUrl + rowData.image} />
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Description</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.description.slice(0, 30)}...
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Reference No.</span>,
      field: "reference_no",
      // render: (rowData) => (
      //   <>
      //     <div style={{ minWidth: 100 }}>
      //       {rowData.reference_no}
      //     </div>
      //   </>
      // ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Total Tickets</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.total_ticket_limitation}
          </div>
        </>
      ),
      sorting: false,
    },

    {
      title: <span className={classes.hed}> Minimum Tickets Limitation</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.minimum_ticket_limitation}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Remaining Tickets</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.remaining_tickets}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Type</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.buy_through_type === "TOKEN" ? "PRIME" : "PROMOTION"}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Draw Closing Date </span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData?.ticket_closing_date?.substring(0, 10)}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Make Winner </span>,
      //  field: "post_code",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData?.winner ? <p style={{ fontWeight: "bold", color: color_palette.primary }}>Winner Announced</p> : <Button style={{ fontSize: 10 }} color="primary" variant="outlined" onClick={() => handleClickOpenMakeWinner(rowData)} >
              Select Winner
            </Button>}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Manage D & G Promotion </span>,
      //  field: "post_code",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {(rowData?.buy_through_type === "TOKEN" || !rowData.active) ?
              <p>No Promotion</p>
              :
              <Button style={{ fontSize: 10 }} color="primary" variant="outlined" onClick={() => handleClickOpenPromotion(rowData)} >
                Open D & G
              </Button>}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Extra Ticket Setup </span>,
      //  field: "post_code",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {(rowData?.buy_through_type === "TOKEN" || !rowData.active) ?
              <p>No Extra Ticket</p>
              :
              <Button style={{ fontSize: 10 }} color="primary" variant="outlined" onClick={() => handleClickOpenExtraTickets(rowData)} >
                Set Extra Ticket
              </Button>}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Status</span>,

      render: (rowData) =>
        rowData.active ? (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked
                style={{ color: color_palette.primary, marginLeft: -15 }}
              />
              Active
            </div>
          </>
        ) : (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked={true}
                disabled
                color={color_palette.primary}
                style={{ marginLeft: -15 }}
              />
              Inactive
            </div>
          </>
        ),
      sorting: false,
    },
    {
      title: <span className={{ float: "right" }}>Actions</span>,
      render: (rowData) => (
        <div className={classes.mnu}>
          <Menu
            key="left"
            direction="right"
            align="center"
            position="anchor"
            viewScroll="auto"
            menuButton={<MoreVertIcon style={{ marginLeft: 20 }}></MoreVertIcon>}
          >
            <MenuItem
              onClick={() =>
                props.history.push({
                  pathname: `/draws/edit_draw/${rowData._id}`,
                  user: rowData,
                })
              }
              className={classes.mnu}
            >
              Edit
            </MenuItem>
            {/* <MenuItem
             // onClick={() => handleClickOpen(rowData)}
              className={classes.mnu}
            >
              Delete
            </MenuItem> */}
            <MenuItem
              onClick={() => props.history.push({
                pathname: `/draws/tickets/${rowData._id}`,
                state: rowData,
              })
              }
              className={classes.mnu}
            >
              View Tickets
            </MenuItem>
          </Menu>
        </div>
      ),
      sorting: false,
    },
  ]);

  const hanldeSearchChange = async (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(false);
    // const data_search = await Customer_search(
    //   page,
    //   rowsPerPage,
    //   e.target.value
    // );
    // console.log(data_search, "DATA SEARCH");
    // if (data_search.code == 200) {
    //   if (data_search.data.customer.length != 0) {
    //     setData(data_search.data.customer);
    //     setTotalpage(data_search.data.total_pages);
    //     setPage(0);
    //   }
    //   setisdata(true);
    // }
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClickOpenMakeWinner = (rowdata) => {
    setrowdata(rowdata);
    setOpenMakeWinnerModal(true);
    get_Tickets(rowdata._id);
  };
  const handleClickOpenPromotion = (rowdata) => {
    setrowdata(rowdata);
    setOpenPromotion(true);
  };
  const handleClickOpenExtraTickets = (rowdata) => {
    setrowdata(rowdata);
    setOpenExtraTicket(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenMakeWinnerModal(false);
    setOpenPromotion(false)
    setOpenExtraTicket(false)
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(false);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(false);
    setRowsPerPage(parseInt(event.target.value));
  };
  const deleteuser = async () => {
    // setOpen(false);
    // setData([]);
    // setisdata(false);
    // const delete_category_resp = await delete_category(selectedrow._id);
    // await get_category(page);
  };

  const handleExtraTicketsSetup = async () => {
    setisdata(true);
    const data = {
      status: extra_ticket_status,
      normal_delivery_tokens: normal_delivery_tokens,
      fast_delivery_tokens: fast_delivery_tokens,
      pick_up_tokens: pick_up_tokens,
      normal_delivery_tickets: normal_delivery_tickets,
      fast_delivery_tickets: fast_delivery_tickets,
      pick_up_tickets: pick_up_tickets,
    };
    // console.log(data, "data ===========")
    // return;
    const response = await set_extra_tickets(selectedrow._id, data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setisdata(false);
    } else {
      enqueueSnackbar("Extra Ticket Setup Successfully", { variant: "success" });
      get_Draws();
      handleClose();
      setisdata(false);
    }

  };

  const handleOpenPromotion = async () => {
    setisdata(true);
    const data = {
      promotion_date: promotion_date,
      promotion_status: promotion_status
    };
    // console.log(data, "data ===========")
    // return;
    const response = await open_diamond_golden_promotions(selectedrow._id, data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setisdata(false);
    } else {
      enqueueSnackbar("Promotion Start Successfully", { variant: "success" });
      get_Draws();
      handleClose();
      setisdata(false);
    }

  };

  const makePrizeWinner = async () => {
    setisdata(true);
    const data = {
      user_id: JSON.parse(selectedWinner).user_id,
      ticket_id: JSON.parse(selectedWinner)._id
    };
    // console.log(data, "data ===========")
    // return;
    const response = await make_prize_winner(data, selectedrow._id);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setisdata(false);
    } else {
      enqueueSnackbar("Draw Winner Announced Successfully", { variant: "success" });
      get_Draws();
      handleClose();
      setisdata(false);
    }

  };

  const get_Tickets = async (id) => {
    const tickets_list_resp = await prize_tickets_user_list(id);
    if (tickets_list_resp.code === 200) {
      console.log(tickets_list_resp, "tickets");
      setTicketsUsers(tickets_list_resp.tickets);
    }
  };
  const get_Draws = async () => {
    const draws_list_resp = await draws_list();
    if (draws_list_resp.code === 200) {
      console.log(draws_list_resp, "countries");
      setTotalpage(draws_list_resp.prizes.length);
      setData(draws_list_resp.prizes);
      setusers(false);
      setisdata(false)
    }
  };
  useEffect(() => {
    get_Draws(page, rowsPerPage);
  }, [rowsPerPage, page]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={() => props.history.push(`/draws/add_draw`)}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Add Draw
          </Button>
          <MaterialTable
            tableRef={tableRef}
            onSearchChange={(e) => console.log("search changed: " + e)}
            style={{ marginTop: "50px" }}
            title="Draws"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            // components={{
            //   Pagination: (props) => (
            //     <TablePagination
            //       component="div"
            //       count={totalPage}
            //       rowsPerPageOptions={[10, 20, 30, 40]}
            //       page={page}
            //       onChangePage={handleChangePage}
            //       rowsPerPage={rowsPerPage}
            //       onChangeRowsPerPage={handleChangeRowsPerPage}
            //     />
            //   ),
            //   Toolbar: (props) => (
            //     <div>
            //       <Box display="flex" p={1}>
            //         <Box p={1} flexGrow={1}>
            //           <h3>Draws</h3>
            //         </Box>
            //         <Box p={1}>
            //           <TextField
            //             id="input-with-icon-textfield"
            //             value={search}
            //             focused
            //             autoFocus={true}
            //             onChange={hanldeSearchChange}
            //             placeholder="Search"
            //             InputProps={{
            //               startAdornment: (
            //                 <InputAdornment
            //                   position="start"
            //                   style={{ float: "right" }}
            //                 >
            //                   <SearchIcon />
            //                 </InputAdornment>
            //               ),
            //             }}
            //           />
            //         </Box>
            //       </Box>
            //     </div>
            //   ),
            // }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == true ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            options={{
              actionsColumnIndex: -1,
              search: true,
              // pageSize: rowsPerPage,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 40, 50],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#007044",
                color: color_palette.secondary,
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.title}`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deleteuser} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={openMakeWinnerModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Select User & Make Winner`}</DialogTitle>
            <DialogContent style={{ width: 500 }}>
              <FormControl variant="outlined" className={classes.formControl} fullWidth autoFocus>
                {/* <InputLabel htmlFor="outlined-age-native-simple">Select Winner</InputLabel> */}
                <Select
                  native
                  value={selectedWinner}
                  onChange={(e) => setSelectedWinner(e.target.value)}
                  autoFocus
                  // label="Select Winner"
                  inputProps={{
                    name: 'winner',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  <option aria-label="None" value="" />
                  {ticketsUser.map((x, i) => (
                    <option value={JSON.stringify(x)}>{x?.user_detail?.name + " - " + x?.profile?.email + " - " + x?.ticket_no}</option>
                  ))}

                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={makePrizeWinner} color="primary">
                Change
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={openPromotion}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Open Diamond & Golden Promotions`}</DialogTitle>
            <DialogContent style={{ width: 500 }}>

              <FormControlLabel
                control={
                  <Switch
                    checked={promotion_status}
                    onChange={(event) => {
                      setPromotion_Status(event.target.checked);
                    }}
                    name="Promotion Opened"
                    color="primary"
                  />
                }
                label="Open Diamond and Golden Promotion"
              />

              {promotion_status &&
                <TextField
                  // required
                  id="outlined-multiline-static"
                  label="Promotion Start Date"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  type="date"
                  autoFocus
                  value={promotion_date}
                  onChange={(e) => setPromotion_Date(e.target.value)}
                  name="Promotion Start Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                Close
              </Button>
              <Button onClick={handleOpenPromotion} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>











          <Dialog
            open={openExtraTicket}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Open Diamond & Golden Promotions`}</DialogTitle>
            <DialogContent style={{ width: 500 }}>

              <FormControlLabel
                control={
                  <Switch
                    checked={extra_ticket_status}
                    onChange={(event) => {
                      setExtra_Ticket_Status(event.target.checked);
                    }}
                    name="Promotion Opened"
                    color="primary"
                  />
                }
                label="Open Extra Tickets against this prize"
              />

              {extra_ticket_status &&
                <>
                  <h3>Tokens Setup</h3>
                  <TextField
                    // required
                    id="outlined-multiline-static"
                    label="Normal Delivery Tokens"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="number"
                    autoFocus
                    value={normal_delivery_tokens}
                    onChange={(e) => setNormal_Delivery_Tokens(e.target.value)}
                    name="Normal Delivery Tokens"

                  />
                  <TextField
                    // required
                    id="outlined-multiline-static"
                    label="Fast Delivery Tokens"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={fast_delivery_tokens}
                    onChange={(e) => setFast_Delivery_Tokens(e.target.value)}
                    name="Fast Delivery Tokens"

                  />
                  <TextField
                    // required
                    id="outlined-multiline-static"
                    label="Pick up Tokens"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={pick_up_tokens}
                    onChange={(e) => setPick_Up_Tokens(e.target.value)}
                    name="Pick up Tokens"

                  />

                  <h3>Tickets Setup</h3>
                  <TextField
                    // required
                    id="outlined-multiline-static"
                    label="Normal Delivery Tickets"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={normal_delivery_tickets}
                    onChange={(e) => setNormal_Delivery_Tickets(e.target.value)}
                    name="Normal Delivery Tickets"

                  />
                  <TextField
                    // required
                    id="outlined-multiline-static"
                    label="Fast Delivery Tickets"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={fast_delivery_tickets}
                    onChange={(e) => setFast_Delivery_Tickets(e.target.value)}
                    name="Fast Delivery Tickets"

                  />
                  <TextField
                    // required
                    id="outlined-multiline-static"
                    label="Pick up Tickets"
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="number"
                    value={pick_up_tickets}
                    onChange={(e) => setPick_Up_Tickets(e.target.value)}
                    name="Pick up Tickets"

                  />
                </>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                Close
              </Button>
              <Button onClick={handleExtraTicketsSetup} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(Draws_Listing);
