import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { color_palette } from "../../../theme/theme";
import { useParams } from "react-router-dom";

// import { clients_list, Customer_search } from "../../DAL/country/clients";
import { default_image } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { download_tickets_pdf, tickets_list } from "../../../DAL/draws/draws";

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function Tickets_Listing(props) {
  const classes = useStyles();
  const { id } = useParams()
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [isdata, setisdata] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalpage] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Ticket No.</span>,
      field: "ticket_no",
      // render: (rowData) => (
      //   <>
      //     <div style={{ minWidth: 100 }}>
      //       {rowData.name}
      //     </div>
      //   </>
      // ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Customer Name</span>,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.user_detail?.name}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Customer Email</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.profile?.email}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Custome Phone Number</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.profile?.phone_number}
          </div>
        </>
      ),
      sorting: false,
    },

    {
      title: <span className={classes.hed}> Prize / Draw</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.prize?.name}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Closing Date</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.prize?.ticket_closing_date?.substring(0, 10)}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Draw Type</span>,
      //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.prize?.buy_through_type === "TOKEN" ? "PRIME" : "PROMOTION"}
          </div>
        </>
      ),
      sorting: false,
    },

    // {
    //   title: <span className={classes.hed}>Status</span>,

    //   render: (rowData) =>
    //     !rowData.status && rowData.status == true ? (
    //       <>
    //         <div style={{ minWidth: 100 }}>
    //           <Radio
    //             checked
    //             style={{ color: color_palette.primary, marginLeft: -15 }}
    //           />
    //           Active
    //         </div>
    //       </>
    //     ) : (
    //       <>
    //         <div style={{ minWidth: 100 }}>
    //           <Radio
    //             checked={true}
    //             disabled
    //             color={color_palette.primary}
    //             style={{ marginLeft: -15 }}
    //           />
    //           Inactive
    //         </div>
    //       </>
    //     ),
    //   sorting: false,
    // },

    // {
    //   title: <span className={{ float: "right" }}>Actions</span>,
    //   render: (rowData) => (
    //     <div className={classes.mnu}>
    //        <Menu
    //         key="left"
    //         direction="right"
    //         align="center"
    //         position="anchor"
    //         viewScroll="auto"
    //         menuButton={<MoreVertIcon style={{marginLeft:20}}></MoreVertIcon>}
    //       >
    //          <MenuItem 
    //           onClick={() =>
    //             props.history.push({
    //               pathname: `/draws/edit_draw/${rowData._id}`,
    //               user: rowData,
    //             })
    //           }
    //           className={classes.mnu}
    //         >
    //           Edit
    //         </MenuItem> 
    //         <MenuItem
    //          // onClick={() => handleClickOpen(rowData)}
    //           className={classes.mnu}
    //         >
    //           Delete
    //         </MenuItem>
    //         <MenuItem
    //         //  onClick={() => get_franchise_detail(rowData)}
    //           className={classes.mnu}
    //         >
    //           Detail
    //         </MenuItem>
    //       </Menu>
    //     </div>
    //   ),
    //   sorting: false,
    // },
  ]);

  const hanldeSearchChange = async (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(false);
    // const data_search = await Customer_search(
    //   page,
    //   rowsPerPage,
    //   e.target.value
    // );
    // console.log(data_search, "DATA SEARCH");
    // if (data_search.code == 200) {
    //   if (data_search.data.customer.length != 0) {
    //     setData(data_search.data.customer);
    //     setTotalpage(data_search.data.total_pages);
    //     setPage(0);
    //   }
    //   setisdata(true);
    // }
  };
  const handleback = () => {
    props.history.goBack();
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(false);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(false);
    setRowsPerPage(parseInt(event.target.value));
  };
  const deleteuser = async () => {
    // setOpen(false);
    // setData([]);
    // setisdata(false);
    // const delete_category_resp = await delete_category(selectedrow._id);
    // await get_category(page);
  };

  const downloadTickets = async () => {
    const tickets_resp = await download_tickets_pdf(props.location.state._id);
    if (tickets_resp.code === 200) {
      console.log(tickets_resp, "tickets_resp");
      // window.open(tickets_resp?.pdf_link)
      if(tickets_resp?.pdf_link){
        window.open(tickets_resp?.pdf_link)
        // const content = await tickets_resp?.pdf_link.blob();
        // content.blob().then(myBlob => {
        //     downloadusingBlob(myBlob, props.location.state.name);
        //   });
        }
    }
    // console.log('item', item);
    // var invoiceNumber = item.reference_number;
    // if (item.type === 'FINE') {
    // try {
    //   const rawResponse = await fetch(
    //     process.env.REACT_APP_API_BASE_URL + `api/ticket/get_tickets_pdf_by_prize_id/${props.location.state._id}`,
    //     {
    //       method: 'DET',
    //       headers: {
    //         "x-sh-auth": localStorage.getItem("token"),
    //       },
    //       // body: JSON.stringify({
    //       //   transaction_ref: item.transaction.ipgTransactionId,
    //       // }),
    //     }
    //   );
    //   if (rawResponse.status === 401) {
    //     // showErrorToastrMessage('Fine is not paid');
    //   } else {
    //     const content = await rawResponse.blob();
    //     downloadusingBlob(content, props.location.state.name);
    //   }
    // } catch (error) {
    //   console.log(error, '========');
    // }
    // } else if (item.type == 'IDA') {
    // fetch(
    //   process.env.REACT_APP_API_BASE_URL + `api/ticket/get_tickets_pdf_by_prize_id/${props.location.state._id}`
    // ).then(response => {
    //       response.blob().then(myBlob => {
    //         downloadusingBlob(myBlob, invoiceNumber);
    //       });
    // });
    // } else {
    //   fetch(process.env.REACT_APP_API_URL + `/invoice-test?id=${item.id}`).then(
    //     response => {
    //       response.blob().then(myBlob => {
    //         downloadusingBlob(myBlob, invoiceNumber);
    //       });
    //     }
    //   );
    // }
  };

  const downloadusingBlob = (myBlob, prize_name) => {
    var objectURL = URL.createObjectURL(myBlob);
    const link = document.createElement('a');
    link.href = objectURL;
    link.setAttribute('download', `tickets-${prize_name}.${'pdf'}`);
    document.body.appendChild(link);
    link.click();
    // window.open(objectURL);
  };

  const downloadTicketsFile = () => {
    const ticketsData = data.map((x,i)=> {
      return `${i+1}. name: ${x.user_detail.name} - Ticket_no: ${x.ticket_no} \r\n`
    })
    const element = document.createElement("a");
    const file = new Blob([ticketsData], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = "tickets.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const get_Tickets = async () => {
    const tickets_list_resp = await tickets_list(id);
    if (tickets_list_resp.code === 200) {
      console.log(tickets_list_resp, "countries");
      setTotalpage(tickets_list_resp.tickets.length);
      setData(tickets_list_resp.tickets);
      setusers(false);
      setisdata(false)
    }
  };
  useEffect(() => {
    get_Tickets(page, rowsPerPage);
  }, [rowsPerPage, page]);
  console.log(props, "props==============")
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              // marginLeft: "-550px",
            }}
            onClick={handleback}
          />
           <Button
            onClick={() => downloadTicketsFile()
              // props.history.push({
              //   pathname: `/draws/print_tickets`,
              //   state: data,
              // })
            }
            // className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Download Tickets File
          </Button>
          <Button
            onClick={() => downloadTickets()
              // props.history.push({
              //   pathname: `/draws/print_tickets`,
              //   state: data,
              // })
            }
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Print Tickets
          </Button>
          <MaterialTable
            tableRef={tableRef}
            onSearchChange={(e) => console.log("search changed: " + e)}
            style={{ marginTop: "50px" }}
            title={props.location.state.name + " " + "Tickets"}
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            // components={{
            //   Pagination: (props) => (
            //     <TablePagination
            //       component="div"
            //       count={totalPage}
            //       rowsPerPageOptions={[10, 20, 30, 40]}
            //       page={page}
            //       onChangePage={handleChangePage}
            //       rowsPerPage={rowsPerPage}
            //       onChangeRowsPerPage={handleChangeRowsPerPage}
            //     />
            //   ),
            //   Toolbar: (props) => (
            //     <div>
            //       <Box display="flex" p={1}>
            //         <Box p={1} flexGrow={1}>
            //           <h3>Draws</h3>
            //         </Box>
            //         <Box p={1}>
            //           <TextField
            //             id="input-with-icon-textfield"
            //             value={search}
            //             focused
            //             autoFocus={true}
            //             onChange={hanldeSearchChange}
            //             placeholder="Search"
            //             InputProps={{
            //               startAdornment: (
            //                 <InputAdornment
            //                   position="start"
            //                   style={{ float: "right" }}
            //                 >
            //                   <SearchIcon />
            //                 </InputAdornment>
            //               ),
            //             }}
            //           />
            //         </Box>
            //       </Box>
            //     </div>
            //   ),
            // }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == true ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            options={{
              actionsColumnIndex: -1,
              search: true,
              // pageSize: rowsPerPage,
              pageSize: 10,
              pageSizeOptions: [10, 20, 30, 40, 50],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#007044",
                color: color_palette.secondary,
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.title}`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deleteuser} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(Tickets_Listing);
