import { invokeApi } from "../../bl_libs/invokeApi";

export const transaction_list = async (to_date, from_date) => {
  let requestObj = {
    path: `api/transaction/get_all_transaction`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    queryParams: {
      to_date,
      from_date,
    }
  };
  return invokeApi(requestObj);
};