import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import {Dialog, Grid, Switch, FormControlLabel } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";

import { color_palette } from "../../theme/theme";
import { clients_list, Customer_search, upload_image_s3 } from "../../DAL/country/country";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
import { send_users_notifications, user_list, change_influencer_status } from "../../DAL/User/UserListing";
import { DialogContent } from "@material-ui/core";
import moment from "moment";

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "right",
    color: "white",
    fontSize: "15px",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  mnu: {
    cursor: "pointer",
  },
});

function Users(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [isdata, setisdata] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalpage] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [search, setSearch] = React.useState("");
  const [openNotifications, setOpenNotifications] = React.useState(false);
  const [notification_title, setNotification_Title] = React.useState("");
  const [notification_description, setNotification_Description] = React.useState("");
  const [arabic_notification_title, setArabic_Notification_Title] = React.useState("");
  const [arabic_notification_description, setArabic_Notification_Description] = React.useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");
  const [openInfluencerStatus, setOpenInfluencerStatus] = useState(false);
  const [influencer_status, setInfluencer_Status] = useState(false);
  const [tokens, setTokens] = useState(0);
  const [iswaiting, setiswaiting] = useState(false);
  const [iswaitingNotification, setiswaitingNotification] = useState(false);
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Name</span>,
      field: "name",
      // render: (rowData) => (
      //   <>
      //     <div style={{ minWidth: 100 }}>
      //       {rowData.full_name ? rowData.full_name : ""}
      //     </div>
      //   </>
      // ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Email</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
          {rowData.user_id?.email}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Phone Number</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
          {rowData.user_id?.phone_number}
          </div>
        </>
      ),
      sorting: false,
    },
    
    {
      title: <span className={classes.hed}> Gender</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
          {rowData?.gender}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Date of Birth</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
          {rowData?.date_of_birth?.substring(0,10)}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Referral Code</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.referal_code}
          </div>
        </>
      ),
      sorting: false,
    },
    // {
    //   title: <span className={classes.hed}> Is Influencer</span>,
    // //  field: "contact_number",
    //   render: (rowData) => (
    //     <>
    //       <div style={{ minWidth: 100 }}>
          
    //       <Switch
    //         checked={rowData?.is_influencer}
    //         onChange={(event) => {
    //           changeInfluencerStatus(rowData?.user_id?._id);
    //         }}
    //         name="influencer"
    //         color="primary"
    //       />
    //       </div>
    //     </>
    //   ),
    //   sorting: false,
    // },
    {
      title: <span className={classes.hed}> Created At</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {moment(rowData.createdAt).format("DD MMM YYYY hh:mm:sa")}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Detail </span>,
    //  field: "post_code",
    render: (rowData) => (
      <>
        <div style={{ minWidth: 100 }}>
          <Button color="primary" variant="outlined" onClick={()=> 
          props.history.push({
            pathname: `/users/detail/${rowData._id}`,
            user: rowData,
          })
          } >
              Details
          </Button>
        </div>
      </>
    ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Make Influencer </span>,
    //  field: "post_code",
    render: (rowData) => (
      <>
        <div style={{ minWidth: 100 }}>
          <Button color="primary" variant="outlined" onClick={()=> 
          handleClickOpenInfluencerStatus(rowData)
          } >
              Make Influencer
          </Button>
        </div>
      </>
    ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Status</span>,

      render: (rowData) =>
        rowData.user_id?.status ? (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked
                style={{ color: color_palette.primary, marginLeft: -15 }}
              />
              Active
            </div>
          </>
        ) : (
          <>
            <div style={{ minWidth: 100 }}>
              <Radio
                checked={true}
                disabled
                color={color_palette.primary}
                style={{ marginLeft: -15 }}
              />
              Inactive
            </div>
          </>
        ),
      sorting: false,
    },
  ]);

  const hanldeSearchChange = async (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(false);
    const data_search = await Customer_search(
      page,
      rowsPerPage,
      e.target.value
    );
    console.log(data_search, "DATA SEARCH");
    if (data_search.code == 200) {
      if (data_search.data.customer.length != 0) {
        setData(data_search.data.customer);
        setTotalpage(data_search.data.total_pages);
        setPage(0);
      }
      setisdata(true);
    }
  };
  const Addcategory = () => {
    props.history.push("/Addcategory");
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };

  const handleClickOpenInfluencerStatus = (rowdata) => {
    console.log(rowdata, "user data")
    setrowdata(rowdata);
    setOpenInfluencerStatus(true);
    setInfluencer_Status(rowdata?.is_influencer);
    setTokens(rowdata?.influencer_tokens);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenNotifications(false);
    setOpenInfluencerStatus(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(true);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(true);
    setRowsPerPage(parseInt(event.target.value));
  };
  const deleteuser = async () => {
    // setOpen(false);
    // setData([]);
    // setisdata(false);
    // const delete_category_resp = await delete_category(selectedrow._id);
    // await get_category(page);
  };

  const handleSendNotification = async ()=> {
    setiswaitingNotification(true)
    const postData ={
      title: notification_title,
      description: notification_description,
      arabic_title: arabic_notification_title,
      arabic_description: arabic_notification_description,
      link: link,
      image: image,
    }
    const user_notification_resp = await send_users_notifications(postData);
    if(user_notification_resp.code === 200){
      enqueueSnackbar("Notification Send Successfully", { variant: "success" });
      setiswaitingNotification(false);
      handleClose();
    }else{
      enqueueSnackbar(user_notification_resp.message, { variant: "error" });
    }
  }
  const changeInfluencerStatus = async (id) => {
    setisdata(true)
    console.log(selectedrow,"selected row")
    setusers(true);
    const data = {
      is_influencer: influencer_status,
      influencer_tokens: tokens
    }
    const list_resp = await change_influencer_status(selectedrow?.user_id?._id, data);
    if(list_resp.code === 200){
      console.log(list_resp, "coupons_list_resp");
      get_Users();
      setusers(false);
      setisdata(false);
      handleClose();
    }
  }
  const get_Users = async () => {
    const user_list_resp = await user_list(page, rowsPerPage);
    if(user_list_resp.code === 200){
      console.log(user_list_resp, "coupons_list_resp");
      setTotalpage(user_list_resp.count);
      setData(user_list_resp.customer);
      setusers(false);
      setisdata(false);
    }
  };
  useEffect(() => {
    get_Users(page, rowsPerPage);
  }, [rowsPerPage, page]);


  const fileChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setFile(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setImage(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };


  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser === true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={()=> setOpenNotifications(true)}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Send Notification
          </Button>
          <MaterialTable
            tableRef={tableRef}
            onSearchChange={(e) => console.log("search changed: " + e)}
            style={{ marginTop: "50px" }}
            title="Users"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            components={{
              Pagination: (props) => (
                <TablePagination
                  component="div"
                  count={totalPage}
                  rowsPerPageOptions={[50, 100, 150, 200]}
                  page={page}
                  onChangePage={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ),
            //   Toolbar: (props) => (
            //     <div>
            //       <Box display="flex" p={1}>
            //         <Box p={1} flexGrow={1}>
            //           <h3>Users</h3>
            //         </Box>
            //         <Box p={1}>
            //           <TextField
            //             id="input-with-icon-textfield"
            //             value={search}
            //             focused
            //             autoFocus={true}
            //             onChange={hanldeSearchChange}
            //             placeholder="Search"
            //             InputProps={{
            //               startAdornment: (
            //                 <InputAdornment
            //                   position="start"
            //                   style={{ float: "right" }}
            //                 >
            //                   <SearchIcon />
            //                 </InputAdornment>
            //               ),
            //             }}
            //           />
            //         </Box>
            //       </Box>
            //     </div>
            //   ),
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata === true ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            options={{
              actionsColumnIndex: -1,
              search: true,
              // pageSize: rowsPerPage,
              pageSize: 50,
              pageSizeOptions: [50,100,150,200,250],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#007044",
                color: color_palette.secondary,
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete `}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deleteuser} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>




          <Dialog
            open={openNotifications}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Send Notifications to Users</DialogTitle>
            <DialogContent>
            {/* <form className={classes.form} onSubmit={handleSendNotification}> */}
            <Grid container spacing={3} >
          
         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Title"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          // id="name"
          value={notification_title}
          onChange={(e)=> setNotification_Title(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Arabic Title"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          // id="name"
          value={arabic_notification_title}
          onChange={(e)=> setArabic_Notification_Title(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <TextField
              required
          id="outlined-multiline-static"
          label="Link"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          // id="name"
          value={link}
          onChange={(e)=> setLink(e.target.value)}
          name="link"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          // id="code"
          value={notification_description}
          onChange={(e)=> setNotification_Description(e.target.value)}
          name="code"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Arabic Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          // id="code"
          value={arabic_notification_description}
          onChange={(e)=> setArabic_Notification_Description(e.target.value)}
          name="code"

        />
         </Grid>

        {iswaiting == true ? (
                      <CircularProgress />
                    ) :
                    <>
                     <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>
              </>}

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid> 
         </Grid>
         {/* </form> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {iswaitingNotification ? <CircularProgress /> : 
               <Button onClick={handleSendNotification} color="primary">
                Send
              </Button>}
            </DialogActions>
          </Dialog>





          <Dialog
            open={openInfluencerStatus}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Influencer Status`}</DialogTitle>
            <DialogContent style={{ width: 500 }}>

              <FormControlLabel
                control={
                  <Switch
                    checked={influencer_status}
                    onChange={(event) => {
                      setInfluencer_Status(event.target.checked);
                    }}
                    name="Influencer Status"
                    color="primary"
                  />
                }
                label="Influencer Status"
              />

              {influencer_status &&
                <TextField
                  // required
                  id="outlined-multiline-static"
                  label="Tokens"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  type="text"
                  autoFocus
                  value={tokens}
                  onChange={(e) => setTokens(e.target.value)}
                  name="Tokens"
                  
                />}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                Close
              </Button>
              <Button onClick={changeInfluencerStatus} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(Users);
