import { invokeApi } from "../../bl_libs/invokeApi";

export const premium_coupons_list = async () => {
  let requestObj = {
    path: `api/premium_coupon/all_premium_coupon_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page,limit,search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_premium_coupon = (data) => {
  let requestObj = {
    path: `api/premium_coupon/add_premium_coupon`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_premium_coupon = (id,data) => {
  let requestObj = {
    path: `api/premium_coupon/edit_premium_coupon/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const premium_coupon_detail = (id) => {
  let requestObj = {
    path: `api/premium_coupon/premium_coupon_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const active_draws_list = async () => {
    let requestObj = {
      path: `api/prize/active_prize_list`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const coupon_tickets_list = async (id) => {
    let requestObj = {
      path: `api/ticket/coupon_tickets/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const coupons_tickets_user_list = async (id) => {
    let requestObj = {
      path: `api/ticket/coupon_tickets/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const make_coupon_winner = (data, id) => {
    let requestObj = {
      path: `api/premium_coupon/add_coupon_winner/${id}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };