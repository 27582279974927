import { invokeApi } from "../../bl_libs/invokeApi";

export const redeem_history_list = async () => {
  let requestObj = {
    path: `api/token_transaction/get_all_token_transaction`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};