import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory, useParams } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControlLabel,
  FormControl
} from "@material-ui/core";
import { active_draws_list, edit_product, product_detail } from "../../DAL/product/product";
import { countries_list, upload_image_s3 } from "../../DAL/country/country";
import { s3baseUrl } from "../../config/config";
import { active_suppliers_list } from "../../DAL/supplier";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Edit_Product(props) {
  const classes = useStyles();
  const history = useHistory();
  const {id} = useParams()
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [arabic_name, setArabic_Name] = useState("");
  const [arabic_description, setArabic_Description] = useState("");
  const [price, setPrice] = useState(0);
  const [stock, setStock] = useState(0);
  const [currency, setCurrency] = useState("");
  const [product_category, setProduct_Category] = useState("");
  const [prize_id, setPrize_Id] = useState("");
  const [donation_tokens, setDonation_Tokens] = useState(0);
  const [supplier, setSupplier] = useState("");
  const [active, setActive] = useState(true);
  const [active_draws, setActive_Draws] = useState([]);
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [countries, setCountries] = useState([]);
  const [suppliersData, setSuppliersData] = useState([]);
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(true);
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);

    let _tokens = (Number(price) * 10)
    console.log(_tokens)

    const data = {
      name,
      arabic_name,
      description,
      arabic_description,
      supplier_id: supplier,
      price,
      donation_tokens: _tokens,
      total_articles:String(stock),
      currency,
      is_show_image: true,
      active,
      image,
      image2
    };
    if(prize_id){
      data.prize_id = prize_id;
    }
    const response = await edit_product(id, data);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Product Updated Successfully", { variant: "success" });
      props.history.push("/product");
    }
    
  
  };

  const get_ActiveDraws = async () => {
    setiswaiting(true)
    const _draws = await active_draws_list();
    if(_draws.code === 200){
      setActive_Draws(_draws.prizes)
      setiswaiting(false)
    }
    
  }

  const get_countries = async () => {
    setiswaiting(true)
    const country = await countries_list();
    if(country.code === 200){
      setCountries(country.countries)
      setiswaiting(false)
    }
    
  }

  const get_Active_Suppliers = async () => {
    setiswaiting(true)
    const supplier_list = await active_suppliers_list();
    if(supplier_list.code === 200){
      setSuppliersData(supplier_list.suppliers)
      setiswaiting(false)
    }
    
  }

  const get_product_detail = async () => {
    setiswaiting(true)
    const product = await product_detail(id);
    if(product.code === 200){
      setName(product.product.name)
      setArabic_Name(product.product?.arabic_name)
      setArabic_Description(product.product?.arabic_description)
      setCurrency(product.product?.currency?._id)
      setPrize_Id(product.product?.prize_id?._id)
      setSupplier(product.product?.supplier_id?._id)
      setPrice(product.product?.price)
      setDonation_Tokens(product.product?.donation_tokens)
      setDescription(product.product?.description)
      setStock(product.product?.total_articles)
      setActive(product.product?.active)
      setFile(s3baseUrl+product.product?.image)
      setImage(product.product?.image)
      setFile2(s3baseUrl+product.product?.image2)
      setImage2(product.product?.image2)
      setiswaiting(false)
    }
    
  }

  useEffect(()=>{
    get_ActiveDraws();
    get_countries();
    get_Active_Suppliers();
    get_product_detail();
  },[])

  const fileChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setFile(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setImage(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };

   const file2ChangedHandler = async (e) => {
    setiswaiting(true);
     console.log(e.target.files[0])
     setFile2(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData(); //formdata object
     formData.append("image", e.target.files[0]);
     const upload_image_resp = await upload_image_s3(formData);
     console.log(upload_image_resp);
    //  setimage(upload_image_resp.path);
     if(upload_image_resp.code == 200){
       setiswaiting(false);
       enqueueSnackbar(upload_image_resp.message, { variant: "success" });
       setImage2(upload_image_resp.path);
     }else{
      enqueueSnackbar(upload_image_resp.message, { variant: "error" });
     }
   };
  
  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }
  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Edit Product</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
            <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e)=> setName(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Arabic Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={arabic_name}
          onChange={(e)=> setArabic_Name(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          id="desc"
          value={description}
          onChange={(e)=> setDescription(e.target.value)}
          name="desc"

        />
        </Grid>

        <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Arabic Description"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={arabic_description}
          onChange={(e)=> setArabic_Description(e.target.value)}
          name="desc"

        />
        </Grid>

        <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Product Stock"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="number"
          value={stock}
          onChange={(e)=> setStock(e.target.value)}
          name="stock"

        />
        </Grid>

<Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Price"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="number"
          value={price}
          onChange={(e)=> setPrice(e.target.value)}
          name="price"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:10}}>
         <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">Select Supplier</InputLabel>
        <Select
          native
          value={supplier}
          onChange={(e)=> setSupplier(e.target.value)}
          label="Select Supplier"
          inputProps={{
            name: 'supplier',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {suppliersData.map((x)=>(
            <>
            <option value={x._id}>{x.name}</option>
          </>
          ))}
        </Select>
      </FormControl>
          </Grid>

         {/* <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Donation Tokens"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          type="number"
          fullWidth
          value={donation_tokens}
          onChange={(e)=> setDonation_Tokens(e.target.value)}
          name="donation_tokens"

        />
         </Grid> */}

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">Currency</InputLabel>
        <Select
          native
          value={currency}
          onChange={(e)=> setCurrency(e.target.value)}
          label="Currency"
          inputProps={{
            name: 'currency',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {countries.map((x)=>(
            <>
            <option value={x._id}>{x.currency}</option>
          </>
          ))}
        </Select>
      </FormControl>
          </Grid>

          <Grid item xs={12}>
         <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel htmlFor="outlined-age-native-simple">Select Prize</InputLabel>
        <Select
          native
          value={prize_id}
          onChange={(e)=> setPrize_Id(e.target.type)}
          label="Select Prize"
          inputProps={{
            name: 'currency',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {active_draws.map((x)=>(
            <>
            <option value={x._id}>{x.name}</option>
          </>
          ))}
        </Select>
      </FormControl>
          </Grid>

          
          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {file ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-file"
                  type="file"
                  onChange={fileChangedHandler}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid> 

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                {file2 ? (
                  <>
                    {" "}
                    <img className={classes.img} src={file2} />{" "}
                  </>
                ) : null}
              </Grid>

              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <input
                  accept="image/x-png,image/jpeg"
                  hidden
                  id="contained-button-image2-file"
                  type="file"
                  onChange={file2ChangedHandler}
                />
                <label htmlFor="contained-button-image2-file">
                  <Button
                    component="span"
                    fullWidth

                    variant="outlined"
                    color="primary"
                  >
                    Upload Sub Image
                  </Button>
                </label>
              </Grid> 

          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={(event)=> setActive(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid> 
             

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Edit Product
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
