import { Container, Typography,Grid, Divider } from '@material-ui/core'
import React from 'react'
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export default function UserDetail(props) {
    const history = useHistory();


    const handleback = () => {
        history.goBack();
      };

    console.log(props,"props ====================")
  return (
    <Container maxWidth="md">
        <ArrowBackIcon
            style={{
              cursor: "pointer",
            //   marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
      <h1 style={{marginTop: 20, textAlign: "center"}}>User Detail:</h1>
      <Grid container spacing={3} style={{marginTop: 20}}>
      <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Name</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.name}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Email</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.user_id?.email}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Phone Number</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.user_id?.phone_number}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Gender</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.gender}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Date of Birth</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.date_of_birth?.substring(0,10)}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Addresses:</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography style={{fontWeight: "bold"}}>{props?.location?.user?.address?.length == 0 && "N/A"}</Typography>
        </Grid>

        {props?.location?.user?.address && props?.location?.user?.address.map((x,i)=>(
            <>
            <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Address {i+1}</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{(x?.apartment_no ? "Apartment no. "+x?.apartment_no : "")+", "+x?.area+", "+x?.state+", "+x?.country}</Typography>
        </Grid>
        <Divider />
        </>
        )) }

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Total Spending Tokens</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.tokens_detail?.spent}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Total Earned Tokens</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.tokens_detail?.earned}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Donation Tokens</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.tokens_detail?.donate_tokens}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Purchased Tokens</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.tokens_detail?.purchased_tokens}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Promotional Tokens</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.tokens_detail?.promotional_tokens}</Typography>
        </Grid>
        <Divider />

        <Grid item xs={6}>
      <Typography style={{fontWeight: "bold"}}>Referral  Tokens</Typography>
        </Grid>
        <Grid item xs={6}>
        <Typography>{props?.location?.user?.tokens_detail?.referral_tokens}</Typography>
        </Grid>
        <Divider />



        </Grid>
      
    </Container>
  )
}
