import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useHistory,useLocation } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
//import { add_instructor,upload_image } from "../../DAL/instructor/instructor";
import { useSnackbar } from "notistack";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  InputLabel,
  Select,
  FormControl,
  FormControlLabel
} from "@material-ui/core";
import { add_supplier_branch } from "../../../DAL/supplier";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#000",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    marginTop: "3%",
    color: "white",

    fontSize: "15px",
  },
  txt: {
    color: "black",
    marginTop: "4%",
    marginBottom:20
  },
  alert: {
    width: "100%",
    marginBottom: 20,
  },
  avtr: {
    width: 80,
    height: 80,
    cursor: "pointer",
    backgroundColor: "#37CD77",
    color: "white",
  },
  blog_img: {
    marginTop: 10,
    width: 550,
    height: 300,
  },
  img: {
    width: "100%",
    height: "100%",
  },
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Add_Branch(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [phone_number, setPhone_Number] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [active, setActive] = useState(true);
  const [countries, setCountries] = useState([]);
  const [errorflag, setflag] = useState("");
  const [iswaiting, setiswaiting] = useState(false);

  console.log(location, "location ====================")
  
  

  const handleback = () => {
    history.goBack();
  };
  const handlesubmit = async (event) => {
    event.preventDefault();
    setiswaiting(true);
    
    const data = {
        branch_name: name,
      branch_email:email,
    branch_contact: phone_number,
      address,
      city,
      // state,
      branch_status: active
    };
    const response = await add_supplier_branch(data, location.state.supplier_id);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setiswaiting(false);
    } else {
      enqueueSnackbar("Branch Added Successfully", { variant: "success" });
      props.history.push(`/suppliers/branches/${location.state.supplier_id}`);
    }
  
  };



//   useEffect(()=>{
//     get_countries();
//   },[])

  if(iswaiting){
    return <CircularProgress style={{paddingTop:"25%", paddingLeft: "50%"}} />
  }
  


  
  return (
    <>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <ArrowBackIcon
            style={{
              cursor: "pointer",
              marginLeft: "-550px",
            }}
            onClick={handleback}
          /> 
          <Avatar className={classes.avatar}>
          <SettingsIcon />
        </Avatar>
          <Typography
            className={classes.txt}
            component="h1"
            variant="h5"
          >Add Branch</Typography>
          {errorflag && (
            <Alert className={classes.alert} severity="error">
              {errorflag}
            </Alert>
          )}
          <form className={classes.form} onSubmit={handlesubmit}>
            <Grid container spacing={3} >
          
         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Branch Name"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={name}
          onChange={(e)=> setName(e.target.value)}
          name="name"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              required
          id="outlined-multiline-static"
          label="Phone Number"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={phone_number}
          onChange={(e)=> setPhone_Number(e.target.value)}
          name="number"

        />
         </Grid>

        
         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              // required
          id="outlined-multiline-static"
          label="Email"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e)=> setEmail(e.target.value)}
          name="email"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              // required
          id="outlined-multiline-static"
          label="Address"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={address}
          onChange={(e)=> setAddress(e.target.value)}
          name="email"

        />
         </Grid>

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              // required
          id="outlined-multiline-static"
          label="City"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={city}
          onChange={(e)=> setCity(e.target.value)}
          name="city"

        />
         </Grid>

         {/* <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
              <TextField
              // required
          id="outlined-multiline-static"
          label="State"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          value={state}
          onChange={(e)=> setState(e.target.value)}
          name="state"

        />
         </Grid> */}

         <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
         <FormControlLabel
        control={
          <Switch
            checked={active}
            onChange={(event)=> setActive(event.target.checked)}
            name="active"
            color="primary"
          />
        }
        label="Status"
      />
         </Grid> 
             

              <Grid item xs={12}
          style={{paddingTop:0,paddingBottom:0}}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Add Branch
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
