import { CircularProgress, Grid } from "@material-ui/core";
import CardBox from "./components/Card/Card";
import CategoryIcon from "@material-ui/icons/Category";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import GroupIcon from "@material-ui/icons/Group";
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { useEffect, useState } from "react";
import { _dashboard_counts } from "../../DAL/dashboard/dashboard";
import { useSnackbar } from "notistack";
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GestureIcon from '@material-ui/icons/Gesture';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import TheatersIcon from '@material-ui/icons/Theaters';
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import PersonIcon from "@material-ui/icons/Person";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import MaterialTable from "material-table";
import { forwardRef } from "react";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
import { color_palette } from "../../theme/theme";

function Dashboard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [latestCustomer, setLatestCustomer] = useState([]);
  const [data, setData] = useState({
    totalCustomer: 0,
  });
  const [columns, SetColumn] = useState([
    {
      title: <span>#</span>,
      width: 50,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span> Name</span>,
      render: (rowData) => <>{rowData.first_name + " " + rowData.last_name}</>,
      sorting: false,
    },
    {
      title: <span> Contact No</span>,
      field: "contact_number",
      sorting: false,
    },
    {
      title: <span> Post code</span>,
      field: "post_code",
      sorting: false,
    },
    {
      title: <span> Image</span>,
      render: (rowData) => (
        <img
          src={
            rowData.profile_image == ""
              ? default_image
              : s3baseUrl + rowData.profile_image
          }
          style={{ width: 50, height: 50 }}
        ></img>
      ),
      sorting: false,
    },
  ]);

  const FetchDashboardCounts = async () => {
    const resp = await _dashboard_counts();
    if (resp.code === 200) {
      setData( resp.dashboard_stats);
      setIsLoading(false);
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };
  useEffect(() => {
    FetchDashboardCounts();
  }, []);

  if (isLoading) {
    return (
      <div className="Loader">
        <CircularProgress />
      </div>
    );
  }

  const cardsData = [
    {
      title: "Total Users",
      icon: <GroupIcon style={{ color: color_palette.secondary }} />,
      color: color_palette.primary,
      count: data.users,
    },
    {
      title: "Total Premium Coupons",
      icon: <ListIcon style={{ color: color_palette.secondary }} />,
      color: color_palette.primary,
      count: data.premium_coupons,
    },
    {
      title: "Total Products",
      icon: <TheatersIcon style={{ color: color_palette.secondary }} />,
      color: color_palette.primary,
      count: data.products,
    },
    {
      title: "Total Winners",
      icon: <AccessibilityIcon style={{ color: color_palette.secondary }} />,
      color: color_palette.primary,
      count: 3,
    },
    {
      title: "Total Draws",
      icon: <GestureIcon style={{ color: color_palette.secondary }} />,
      color: color_palette.primary,
      count: data.draws,
    },
    {
      title: "Total Orders",
      icon: <GestureIcon style={{ color: color_palette.secondary }} />,
      color: color_palette.primary,
      count: data.orders,
    },
  ];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        justify="left"
        alignItems="center"
        spacing={2}
      >
        {cardsData.map((card) => (
          <Grid item xs={12} sm={12} md={4} lg={4} style={{ marginTop: 20 }}>
            <CardBox data={card} />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        direction="row"
        justify="left"
        alignItems="center"
        spacing={2}
      ></Grid>
    </div>
  );
}

export default Dashboard;
