import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import {Dialog, Divider, Grid} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CSVLink } from "react-csv";
import Radio from "@material-ui/core/Radio";
import TablePagination from "@material-ui/core/TablePagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@material-ui/core/Box";
import { color_palette } from "../../theme/theme";
// import { clients_list, Customer_search } from "../../DAL/country/clients";
import { default_image } from "../../assets";
import { s3baseUrl } from "../../config/config";
import { useSnackbar } from "notistack";
import { order_history_list } from "../../DAL/order_history";
import { DialogContent, InputLabel, FormControl, Select, Typography } from "@material-ui/core";
import { change_order_status } from "../../DAL/order_history";
import moment from "moment";
import { products_list } from "../../DAL/product/product";

//-------dialog-transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  hed: {
    fontWeight: "bold",
  },
  clmwdth: {
    width: "10px",
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  btnadd: {
    float: "left",
    color: "white",
    fontSize: "15px",
  },
  mnu: {
    cursor: "pointer",
  },
});

function OrderHistory(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [selectedrow, setrowdata] = React.useState([]);
  const [isuser, setusers] = React.useState(true);
  const [isdata, setisdata] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [orderStatus, setOrderStatus] = React.useState("");
  const [openChangeOrderStatusModal, setOpenChangeOrderStatusModal] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalpage] = React.useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  const [openFilter, setOpenFilter] = React.useState(false);
  const [to_date, setTo_Date] = React.useState("");
  const [from_date, setFrom_Date] = React.useState("");
  const [order_option, setOrder_Option] = React.useState("");
  const [filterOrderStatus, setFilterOrderStatus] = React.useState("");
  const [productData, setProductData] = React.useState([]);
  const [product_id, setProduct_Id] = React.useState("");
  const [prize_id, setPrize_Id] = React.useState("");
  const [columns, setColumns] = React.useState([
    {
      title: <span className={classes.hed}>#</span>,
      width: 150,
      render: (rowData) => <>{rowData.tableData.id + 1}</>,
    },
    {
      title: <span className={classes.hed}> Order no.</span>,
      field: "order_id",
      // render: (rowData) => (
      //   <>
      //     <div style={{ minWidth: 100 }}>
      //       {rowData.order_id}
      //     </div>
      //   </>
      // ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Customer Name</span>,
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.user_id?.customer_id?.name}
          </div>
        </>
      ),
      sorting: false,
    },
    {
        title: <span className={classes.hed}> Products Quantity</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {getProductQuantity(rowData.products)}
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Product Buying Types</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.products?.length}
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Selection Option</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.order_option == 1 ? "Deliver Product to User (Fast Delivery)" : 
              rowData.order_option == 2 ? "Pick up from HQ" : 
              rowData.order_option == 3 ? "Donate and get Extra Tokens" : 
              rowData.order_option == 3 ?
              "Donate and get Extra Tickets" :
              "Free Delivery"
              }
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Donation Tokens</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.donated?.donation_tokens}
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Donation Tickets</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {rowData.donated?.donation_tickets}
            </div>
          </>
        ),
        sorting: false,
      },
      {
        title: <span className={classes.hed}> Order Date</span>,
      //  field: "contact_number",
        render: (rowData) => (
          <>
            <div style={{ minWidth: 100 }}>
              {moment(rowData.createdAt).format("DD MMM YYYY hh:mm:sa")}
            </div>
          </>
        ),
        sorting: false,
      },
    {
      title: <span className={classes.hed}> Sub Total Amount</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {(rowData.total_amount - rowData.vat_amount).toFixed(2)  + " AED"}
            {/* {getProductPrice(rowData?.products) + " " + rowData.products[0]?.product_id?.currency?.currency} */}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Delivery Charges</span>,
    //  field: "amount",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData?.delivery_charges + " AED"}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> VAT</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData?.vat_amount?.toFixed(2)  + " AED"}
            {/* {getProductPrice(rowData?.products) + " " + rowData.products[0]?.product_id?.currency?.currency} */}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Discount</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData?.discount ? rowData?.discount?.toFixed(2)  + " AED" : "N/A"}
            {/* {getProductPrice(rowData?.products) + " " + rowData.products[0]?.product_id?.currency?.currency} */}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Total Amount</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.total_amount  + " AED"}
            {/* {getProductPrice(rowData?.products) + " " + rowData.products[0]?.product_id?.currency?.currency} */}
          </div>
        </>
      ),
      sorting: false,
    },
    // {
    //   title: <span className={classes.hed}> Recieved Token</span>,
    // //  field: "contact_number",
    //   render: (rowData) => (
    //     <>
    //       <div style={{ minWidth: 100 }}>
    //         {rowData.products[0]?.product_id?.donation_tokens}
    //       </div>
    //     </>
    //   ),
    //   sorting: false,
    // },
    {
      title: <span className={classes.hed}> Prize Name</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          {rowData.products.map((x,i)=>(
            <>
            <div style={{ minWidth: 100, color: "blue", textDecoration: "underline", cursor: "pointer" }} onClick={() => props.history.push({
              pathname: `/draws/tickets/${x?.product_id?.prize_id?._id}`,
              state: x?.product_id?.prize_id,
            })
          }>
            {i+1}. {x?.product_id?.prize_id?.name}
          </div>
          </>
          ))}
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Prize Refernce Number</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {rowData.products[0]?.product_id?.prize_id?.reference_no}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Paid / Un-Paid</span>,
    //  field: "contact_number",
      render: (rowData) => (
        <>
          <div style={{ minWidth: 100 }}>
            {"Paid"}
          </div>
        </>
      ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}>Change Status </span>,
    //  field: "post_code",
    render: (rowData) => (
      <>
        <div style={{ minWidth: 100 }}>
          <Button style={{fontSize: 10}} color="primary" variant="outlined" onClick={()=> handleClickOpenChangeOrderStatus(rowData)} >
          {rowData.order_status}
          </Button>
        </div>
      </>
    ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Customer/Order Detail </span>,
    //  field: "post_code",
    render: (rowData) => (
      <>
        <div style={{ minWidth: 100 }}>
          <Button style={{fontSize: 10}} color="primary" variant="outlined" onClick={()=> handleClickOpenDetail(rowData)} >
               Detail
          </Button>
        </div>
      </>
    ),
      sorting: false,
    },
    {
      title: <span className={classes.hed}> Order Tickets </span>,
    //  field: "post_code",
    render: (rowData) => (
      <>
        <div style={{ minWidth: 100 }}>
          <Button style={{fontSize: 10}} color="primary" variant="outlined" onClick={()=> 
          props.history.push({
            pathname: `/order_history/order_detail_tickets/${rowData._id}`,
            user: rowData,
          })
            // props.history.push(`/order_history/order_detail_tickets/${rowData.order_id}`)
            } >
               Tickets
          </Button>
        </div>
      </>
    ),
      sorting: false,
    },
    // {
    //   title: <span className={classes.hed}>Status</span>,

    //   render: (rowData) =>
    //     rowData.status ? (
    //       <>
    //         <div style={{ minWidth: 100 }}>
    //           <Radio
    //             checked
    //             style={{ color: color_palette.primary, marginLeft: -15 }}
    //           />
    //           Active
    //         </div>
    //       </>
    //     ) : (
    //       <>
    //         <div style={{ minWidth: 100 }}>
    //           <Radio
    //             checked={true}
    //             disabled
    //             color={color_palette.primary}
    //             style={{ marginLeft: -15 }}
    //           />
    //           Inactive
    //         </div>
    //       </>
    //     ),
    //   sorting: false,
    // },
  ]);

  const headers = [
    { label: "Order no.", key: "order_no" },
    { label: "Customer Name", key: "customer_name" },
    { label: "Customer Email", key: "customer_email" },
    { label: "Customer Contact no.", key: "customer_contact_no" },
    { label: "Customer Second Contact No", key: "customer_second_contact_no"},
    { label: "Product Quantity", key: "product_quantity" },
    { label: "Selected Option", key: "selected_option" },
    { label: "Address Information", key: "address_info" },
    { label: "Order Date", key: "order_date" },
    // { label: "Sub total", key: "sub_total" },
    // { label: "Delivery Charges", key: "delivery_charges" },
    // { label: "VAT", key: "vat" },
    // { label: "Total Amount", key: "total_amount" },
    { label: "Prize Reference no.", key: "prize_reference_no" },
    { label: "Order Status", key: "order_status" },
  ];

  const getOrderOption = (rowData) => {
    return rowData.order_option == 1 ? "Deliver Product to User (Fast Delivery)" : 
              rowData.order_option == 2 ? "Pick up from HQ" : 
              rowData.order_option == 3 ? "Donate and get Extra Tokens" : 
              rowData.order_option == 4 ?
              "Donate and get Extra Tickets" :
              "Free Delivery";
  }

  const getProductQuantity = (productDetail) => {
    let count = 0;
      productDetail.map((x,i)=> {
        count = x.product_count + count;
    })

    return count;
  }

  const getCSVData = () => {
    const exportedData = data.map((x,i)=> {
      return {
        order_no: x.order_id,
        customer_name: x.user_id?.customer_id?.name,
        customer_email: x.user_id?.email,
        customer_contact_no: x.user_id?.customer_id?.country_code+" "+x.user_id?.phone_number,
        customer_second_contact_no: x.order_address ? x.order_address?.phone_number : "",
        product_quantity: getProductQuantity(x.products),
        selected_option: getOrderOption(x),
        address_info: x?.order_address ? (x?.order_address?.apartment_no ? "Apartment no. "+x?.order_address?.apartment_no : "")+","+x?.order_address?.area+","+x?.order_address?.state+","+x?.order_address?.country : "",
        order_date: moment(x.createdAt).format("DD MMM YYYY hh:mm:sa"),
        // sub_total: x.total_amount - x.vat_amount  + " AED",
        // delivery_charges: x?.delivery_charges + " AED",
        // vat: x?.vat_amount?.toFixed(2)  + " AED",
        // total_amount: x.total_amount  + " AED",
        prize_reference_no: x.products[0]?.product_id?.prize_id?.reference_no,
        order_status: x.order_status,
      }
    })
    return exportedData;
  }

  const csvReport = {
    data: getCSVData(),
    headers: headers,
    filename: 'download_report.csv'
  };
  // const csvData =[
  //   ['firstname', 'lastname', 'email'] ,
  //   ['John', 'Doe' , 'john.doe@xyz.com'] ,
  //   ['Jane', 'Doe' , 'jane.doe@xyz.com']
  // ];
  

  const handleDownloadReports = () =>{
   
      //  console.log("response", response)
       const url = window.URL.createObjectURL(new Blob([data])) 
       const link = document.createElement('a')
       link.href = url
       const fileName = `downloaded Report ${moment(new Date()).format("DD MMM YY")}.csv`;
       link.setAttribute('download', fileName)
       document.body.appendChild(link)
       link.click()
       link.remove()
      
   }

  const hanldeSearchChange = async (e) => {
    setSearch(e.target.value);
    setData([]);
    setisdata(false);
    // const data_search = await Customer_search(
    //   page,
    //   rowsPerPage,
    //   e.target.value
    // );
    // console.log(data_search, "DATA SEARCH");
    // if (data_search.code == 200) {
    //   if (data_search.data.customer.length != 0) {
    //     setData(data_search.data.customer);
    //     setTotalpage(data_search.data.total_pages);
    //     setPage(0);
    //   }
    //   setisdata(true);
    // }
  };
  const Addcategory = () => {
    props.history.push("/Addcategory");
  };
  const handleClickOpenDetail = (rowdata) => {
    setrowdata(rowdata);
    setOpenDetail(true);
  };
  const handleClickOpenChangeOrderStatus = (rowdata) => {
    setrowdata(rowdata);
    setOpenChangeOrderStatusModal(true);
    setOrderStatus(rowdata.order_status)
  };
  const handleClickOpen = (rowdata) => {
    setrowdata(rowdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenDetail(false);
    setOpenChangeOrderStatusModal(false)
    setOpenFilter(false);
  };
  const handleChangePage = (event, newPage) => {
    setData([]);
    setisdata(false);
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    tableRef.current.dataManager.changePageSize(parseInt(event.target.value));
    setData([]);
    setisdata(false);
    setRowsPerPage(parseInt(event.target.value));
  };

  const handleChangeOrderStatus = async (event) => {
    setisdata(true);
    const data = {
      order_status: orderStatus
    };
    const response = await change_order_status(data, selectedrow._id);
    console.log(response, "response");
    if (response.code !== 200) {
      enqueueSnackbar(response.message, { variant: "error" });
      setisdata(false);
    } else {
      enqueueSnackbar("Status Changed Successfully", { variant: "success" });
      get_Orders();
      handleClose();
      setisdata(false);
    }
    
  
  };
  const deleteuser = async () => {
    // setOpen(false);
    // setData([]);
    // setisdata(false);
    // const delete_category_resp = await delete_category(selectedrow._id);
    // await get_category(page);
  };

  const handleApplyFilter = async ()=> {
    // const postData ={
    //   to_date,
    //   from_date,
    //   order_option,
    // }
    setData([]);
    setisdata(true);
    // setusers(true);
    handleClose();
    const order_list_resp = await order_history_list(to_date, from_date, order_option, filterOrderStatus, product_id, prize_id);
    if(order_list_resp.code === 200){
      // enqueueSnackbar("Notification Send Successfully", { variant: "success" });
      // setiswaitingNotification(false);
      setTotalpage(order_list_resp.orders.length);
      setData(order_list_resp.orders);
      handleClose();
      setusers(false);
      setisdata(false);
    }else{
      enqueueSnackbar(order_list_resp.message, { variant: "error" });
    }
  }

  const handleResetFilter = async () => {
    handleClose();
    setData([]);
    setisdata(true);
    
    // setusers(true);
    const order_list_resp = await order_history_list();
    if(order_list_resp.code === 200){
      console.log(order_list_resp, "orders_list_resp");
      setTotalpage(order_list_resp.orders.length);
      setData(order_list_resp.orders);
      handleClose();
      setusers(false);
      setisdata(false);
    }
    // setisdata(true);
  }

  const getProductPrice = (products) => {
    let price = 0;
    products && products.map((x,i)=>{
      price = Number(x.product_id.price)+price
    })
    console.log(price,"price =========")
    return price;
  }
  console.log(selectedrow,"price =========")

  const get_Products = async () => {
    const products_list_resp = await products_list();
    if(products_list_resp.code === 200){
      console.log(products_list_resp, "countries");
      setProductData(products_list_resp.products);
      setusers(true);
      setisdata(true)
    }
  };

  const get_Orders = async () => {
    setisdata(true);
    const order_list_resp = await order_history_list(to_date, from_date, order_option, filterOrderStatus, product_id, prize_id);
    if(order_list_resp.code === 200){
      console.log(order_list_resp, "orders_list_resp");
      setTotalpage(order_list_resp.orders.length);
      setData(order_list_resp.orders);
      handleClose();
      setusers(false);
      setisdata(false);
    }
  };
  useEffect(() => {
    get_Orders(page, rowsPerPage);
    get_Products()
  }, [rowsPerPage, page]);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <>
      {isuser == true ? (
        <CircularProgress style={{ marginTop: "15%", marginLeft: "45%" }} />
      ) : (
        <MuiThemeProvider>
          <Button
            onClick={()=> setOpenFilter(true)}
            className={classes.btnadd}
            variant="contained"
            color="primary"
          >
            Filter
          </Button>
          
          <CSVLink 
          {...csvReport}
          >
            {/* Export to CSV */}
            <Button
            // onClick={()=> handleDownloadReports()}
            className={classes.btnadd}
            variant="contained"
            color="primary"
            style={{float: "right"}}
          >
            Export CSV.
          </Button>
          </CSVLink>
          <MaterialTable
            tableRef={tableRef}
            onSearchChange={(e) => console.log("search changed: " + e)}
            style={{ marginTop: "50px" }}
            title="Order History"
            icons={tableIcons}
            columns={columns.map((c) => ({ ...c, tableData: undefined }))}
            // components={{
            //   Pagination: (props) => (
            //     <TablePagination
            //       component="div"
            //       count={totalPage}
            //       rowsPerPageOptions={[10, 20, 30, 40]}
            //       page={page}
            //       onChangePage={handleChangePage}
            //       rowsPerPage={rowsPerPage}
            //       onChangeRowsPerPage={handleChangeRowsPerPage}
            //     />
            //   ),
            //   Toolbar: (props) => (
            //     <div>
            //       <Box display="flex" p={1}>
            //         <Box p={1} flexGrow={1}>
            //           <h3>Order History</h3>
            //         </Box>
            //         <Box p={1}>
            //           <TextField
            //             id="input-with-icon-textfield"
            //             value={search}
            //             focused
            //             autoFocus={true}
            //             onChange={hanldeSearchChange}
            //             placeholder="Search"
            //             InputProps={{
            //               startAdornment: (
            //                 <InputAdornment
            //                   position="start"
            //                   style={{ float: "right" }}
            //                 >
            //                   <SearchIcon />
            //                 </InputAdornment>
            //               ),
            //             }}
            //           />
            //         </Box>
            //       </Box>
            //     </div>
            //   ),
            // }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <div style={{ padding: 20 }}>
                    {isdata == true ? (
                      <CircularProgress />
                    ) : (
                      <h5>No data found to display</h5>
                    )}
                  </div>
                ),
              },
            }}
            data={data}
            options={{
              actionsColumnIndex: -1,
              search: true,
              // pageSize: rowsPerPage,
              pageSize: 50,
              pageSizeOptions: [50,100,150,200,250],
              emptyRowsWhenPaging: false,
              headerStyle: {
                backgroundColor: "#007044",
                color: color_palette.secondary,
                fontWeight: "bold",
              },
            }}
          />
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Are you sure you want to Delete ${selectedrow.title}`}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={deleteuser} color="primary">
                Agree
              </Button>
            </DialogActions>
          </Dialog>



          <Dialog
            open={openFilter}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Filter your Data</DialogTitle>
            <DialogContent>
            <Grid container spacing={3} >

            <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
               <TextField
              id="outlined-multiline-static"
              label="From Date"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              type="date"
              autoFocus
              
              InputLabelProps={{
                shrink: true,
              }}
           value={from_date}
           onChange={(e)=> setFrom_Date(e.target.value)}
           name="name"
 
         />
          </Grid>
          
          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
               <TextField
          id="outlined-multiline-static"
          label="To Date"
          className={classes.textField}
          margin="normal"
          variant="outlined"
          fullWidth
          type="date"
          autoFocus
          InputLabelProps={{
            shrink: true,
          }}
           value={to_date}
           onChange={(e)=> setTo_Date(e.target.value)}
           name="name"
 
         />
          </Grid>
          
 
          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0, marginTop: 10}}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth autoFocus>
        <InputLabel htmlFor="outlined-age-native-simple">Select Order Option</InputLabel>
        <Select
          native
          value={order_option}
          onChange={(e)=> setOrder_Option(e.target.value)}
          autoFocus
          label="Select Order Option"
          inputProps={{
            name: 'Select Order Option',
            id: 'outlined-Select-Order-Option-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value={1}>Delivery Product to me</option>
          <option value={2}>Pickup from Suppliers</option>
          <option value={3}>Donate Product and get extra tokens </option>
          <option value={4}>Donate Product and get extra tickets</option>
          
        </Select>
      </FormControl>
          </Grid>

          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0, marginTop: 20}}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth autoFocus>
        <InputLabel htmlFor="outlined-Status-native-simple">Select Order Status</InputLabel>
        <Select
          native
          value={filterOrderStatus}
          onChange={(e)=> setFilterOrderStatus(e.target.value)}
          autoFocus
          label="Select Order Status"
          inputProps={{
            name: 'Select Status Option',
            id: 'outlined-Select-Status-Option-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value="pending">Pending</option>
          <option value="delivered">Delivered</option>
          <option value="donated">Donated</option>
          <option value="cancelled">Cancelled</option>
          
        </Select>
      </FormControl>
          </Grid>

          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0, marginTop: 20}}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth autoFocus>
        <InputLabel htmlFor="outlined-Product-native-simple">Select Product</InputLabel>
        <Select
          native
          value={product_id}
          onChange={(e)=> setProduct_Id(e.target.value)}
          autoFocus
          label="Select Product"
          inputProps={{
            name: 'Select Product',
            id: 'outlined-Select-Product-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {productData.map((x,i)=>(
            <option value={x._id}>{x.name}</option>
          ))}
          
          
        </Select>
      </FormControl>
          </Grid>


          <Grid item xs={12} style={{paddingTop:0,paddingBottom:0, marginTop: 20}}>
          <FormControl variant="outlined" className={classes.formControl} fullWidth autoFocus>
        <InputLabel htmlFor="outlined-Prize-native-simple">Select Prize</InputLabel>
        <Select
          native
          value={prize_id}
          onChange={(e)=> setPrize_Id(e.target.value)}
          autoFocus
          label="Select Prize"
          inputProps={{
            name: 'Select Prize',
            id: 'outlined-Select-Prize-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {productData.map((x,i)=>(
            <>
            {x.prize_id?.active == true && <option value={x.prize_id?._id}>{x.prize_id?.name}</option>}
            </>
          ))}
          
        </Select>
      </FormControl>
          </Grid>
 
        
          </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleResetFilter}>
                Reset
              </Button>
              <Button onClick={handleApplyFilter} color="primary">
                Apply
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={openDetail}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Order / Customer Detail`}</DialogTitle>
            <DialogContent style={{width: 500}}>
              {/* <div style={{display: "flex"}}> */}
              <Grid container spacing={3} >
          
            <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Order Number {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{selectedrow.order_id}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              {/* </div> */}
              {/* <div style={{display: "flex"}}> */}
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Customer Name {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{selectedrow.user_id?.customer_id?.name}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              {/* </div> */}
              {/* <div style={{display: "flex"}}> */}
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Customer Email {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{selectedrow.user_id?.email}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              {/* </div> */}
              {/* <div style={{display: "flex"}}> */}
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Customer Number {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{selectedrow.user_id?.phone_number}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              {/* </div> */}
              {selectedrow?.order_address && 
              // <div style={{display: "flex"}}>
              <>
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Customer Address {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{(selectedrow?.order_address?.apartment_no ? "Apartment no. "+selectedrow?.order_address?.apartment_no : "")+","+selectedrow?.order_address?.area+","+selectedrow?.order_address?.state+","+selectedrow?.order_address?.country}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              </>
              // </div>
              }
              
              {/* <div style={{display: "flex"}}> */}
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Products {" "}</Typography>
              </Grid>
              
              {selectedrow?.products && selectedrow?.products.map((x,i)=>(
                <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Typography style={{marginLeft: 30}}>{x.product_id.name}: {x.product_id.price} {x.product_id.currency?.currency}</Typography>
                </Grid>
              ))}
              
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              
              {/* </div> */}
              {selectedrow?.product_id && 
              // <div style={{display: "flex"}}>
              <>
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Price {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{getProductPrice(selectedrow?.products) + " " + selectedrow.products[0]?.product_id?.currency?.currency}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              </>
              // </div>
              }
              
              {/* <div style={{display: "flex"}}> */}
              <Grid item xs={4} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{fontWeight: "bold"}}>Order Status {" "}</Typography>
              </Grid>
              <Grid item xs={8} style={{paddingTop:0,paddingBottom:0}}>
              <Typography style={{marginLeft: 30}}>{selectedrow.order_status?.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={12} style={{paddingTop:0,paddingBottom:0}}>
                <Divider />
              </Grid>
              {/* </div> */}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              {/* <Button onClick={deleteuser} color="primary">
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>


          <Dialog
            open={openChangeOrderStatusModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{`Change Order Status`}</DialogTitle>
            <DialogContent style={{width: 500}}>
            <FormControl variant="outlined" className={classes.formControl} fullWidth autoFocus>
        {/* <InputLabel htmlFor="outlined-age-native-simple">Select Order Status</InputLabel> */}
        <Select
          native
          value={orderStatus}
          onChange={(e)=> setOrderStatus(e.target.value)}
          autoFocus
          // label="Select Order Status"
          inputProps={{
            name: 'currency',
            id: 'outlined-age-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option value="pending">Pending</option>
          <option value="delivered">Delivered</option>
          <option value="donated">Donated</option>
          <option value="cancelled">Cancelled</option>
          
        </Select>
      </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
              <Button onClick={handleChangeOrderStatus} color="primary">
                Change
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      )}
    </>
  );
}
export default withRouter(OrderHistory);
