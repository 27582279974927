import { invokeApi } from "../../bl_libs/invokeApi";

export const draws_list = async () => {
  let requestObj = {
    path: `api/prize/all_prize_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const Customer_search = async (page,limit,search_text) => {
  let requestObj = {
    path: `api/customer/search_customer?text=${search_text}&page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const add_draw = (data) => {
  let requestObj = {
    path: `api/prize/add_prize`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const edit_draw = (id,data) => {
  let requestObj = {
    path: `api/prize/edit_prize/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const draw_detail = (id) => {
  let requestObj = {
    path: `api/prize/prize_detail/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const tickets_list = async (id) => {
  let requestObj = {
    path: `api/ticket/prize_tickets/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const prize_tickets_user_list = async (id) => {
    let requestObj = {
      path: `api/ticket/prize_tickets/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const make_prize_winner = (data, id) => {
    let requestObj = {
      path: `api/prize/add_prize_winner/${id}`,
      method: "POST",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const open_diamond_golden_promotions = (id,data) => {
    let requestObj = {
      path: `api/prize/edit_promotion_date/${id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };

  export const download_tickets_pdf = async (id) => {
    let requestObj = {
      path: `api/ticket/get_tickets_pdf_by_prize_id/${id}`,
      method: "GET",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
    };
    return invokeApi(requestObj);
  };

  export const set_extra_tickets = (id,data) => {
    let requestObj = {
      path: `api/prize/add_prize_extra_tickets/${id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };